import React, { useEffect, useState, useRef } from "react";
import "./stylist-horizontal-card.scss";
import { IoLocationSharp } from "react-icons/io5";
import { dotSpinner } from "ldrs";

dotSpinner.register();

const StylistHorizontalCard = () => {
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [stylistDetails, setStylistDetails] = useState({
    stylistName: "John Doe",
    specialization: "Hair Stylist",
    rating: 4.8,
    reviews: 120,
    aboutMe: "Experienced hair stylist specializing in modern cuts and color.",
    location: "Downtown, NY",
    offers: [
      { service: "Haircut", discount: "15% Off" },
      { service: "Hair Color", discount: "20% Off" },
      { service: "Styling", discount: "10% Off" },
      { service: "Keratin Treatment", discount: "25% Off" },
    ],
  });

  const tickerRef = useRef(null);

  useEffect(() => {
    // Set a placeholder image and simulate data loading
    setTimeout(() => {
      setImage("https://via.placeholder.com/300x200.png?text=Stylist");
      setIsLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (tickerRef.current) {
      const contentWidth = tickerRef.current.scrollWidth;
      const containerWidth = tickerRef.current.offsetWidth;

      const speed = Math.max(10, contentWidth / 50);
      tickerRef.current.style.animationDuration = `${speed}s`;
    }
  }, [stylistDetails.offers]);

  return (
    <div className="relative w-[300px] h-[360px] border border-gray-200 rounded-xl shadow-sm overflow-hidden bg-white">
      {isLoading ? (
        <div className="flex justify-center items-center h-[168px] bg-gray-100">
          <l-dot-spinner size="40" speed="0.9" color="black"></l-dot-spinner>
        </div>
      ) : (
        <>
          {/* Stylist Image */}
          <div className="w-full h-[168px] bg-gray-100 relative">
            {image ? (
              <img
                src={image}
                alt="Stylist"
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="w-full h-full bg-gray-200"></div>
            )}

            {/* Rating and Reviews Overlay */}
            {stylistDetails.rating && stylistDetails.reviews && (
              <div className="absolute top-0 right-0 bg-[rgba(0,0,0,0.5)] text-white text-xs p-2 rounded-bl-lg text-center">
                <span className="text-lg font-semibold">
                  {stylistDetails.rating.toFixed(1)}
                </span>
                <span className="text-xs block">
                  {stylistDetails.reviews} reviews
                </span>
              </div>
            )}
          </div>

          {/* Stylist Details */}
          <div className="px-4 py-3 flex flex-col justify-between">
            {/* Stylist Name */}
            <span className="text-lg font-bold text-gray-900 truncate text-center pb-2">
              {stylistDetails.stylistName}
            </span>

            {/* Specialization Section */}
            <span className="text-blue-500 text-center text-sm font-semibold pb-2">
              {stylistDetails.specialization}
            </span>

            {/* About Me Section */}
            <span className="text-gray-600 text-center text-sm line-clamp-2 flex-grow pb-2">
              {stylistDetails.aboutMe}
            </span>

            {/* Location Section */}
            {stylistDetails.location && (
              <div className="flex items-center justify-center text-gray-500 text-sm mt-2">
                <IoLocationSharp className="text-red-500 text-xl mr-1" />
                <span className="truncate">{stylistDetails.location}</span>
              </div>
            )}

            {/* Offers Section with Ticker */}
            {stylistDetails.offers.length > 0 && (
              <div className="ticker-container pt-2">
                <div className="ticker-content" ref={tickerRef}>
                  {stylistDetails.offers.map((offer, index) => (
                    <span key={index} className="inline-block mx-2">
                      {offer.service}:{" "}
                      <span className="font-bold text-[#C84C05]">
                        {offer.discount}
                      </span>
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default StylistHorizontalCard;
