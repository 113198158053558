import React, { useState, useEffect, useRef } from "react";
import ServiceShop from "../service-shops/service-shops";
import { useParams, useNavigate } from "react-router-dom";
import HomeService from "../../services/HomeService";
import { gsap } from "gsap";

const availableServices = [
  "Haircut",
  "Facials",
  "Hair Spa",
  "Detanning",
  "Hair Coloring",
  "Highlights",
  "Root Touch-up",
];

function OfferDiscounts() {
  const { service: serviceParam } = useParams();
  const [service, setService] = useState(serviceParam);
  const [offers, setOffers] = useState([]);
  const [sortedOffers, setSortedOffers] = useState([]);
  const [sortOption, setSortOption] = useState("Ratings");
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const heroRef = useRef(null);
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1, yoyo: true });

    gsap.to(heroRef.current, {
      backgroundPosition: "200% 0%",
      duration: 10,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });

    tl.fromTo(
      titleRef.current,
      { y: 0, scale: 1 },
      {
        y: -10,
        scale: 1.05,
        duration: 2,
        ease: "power2.inOut",
      }
    );

    tl.fromTo(
      subtitleRef.current,
      { y: 0, opacity: 0.8 },
      {
        y: 10,
        opacity: 1,
        duration: 2.5,
        ease: "power2.inOut",
      },
      "<"
    );
  }, []);

  const fetchOffers = async () => {
    setIsLoading(true);
    try {
      const res = await HomeService.GetServiceShopsList(service);
      const data = res.data.services || [];

      const shopsWithOffers = data.filter(
        (shop) =>
          shop.discountPrice &&
          shop.originalPrice &&
          shop.discountPrice < shop.originalPrice
      );

      setOffers(shopsWithOffers);
      sortShops(sortOption, shopsWithOffers);
    } catch (error) {
      console.error("Error fetching shops with offers:", error);
      setOffers([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, [service]);

  const sortShops = (option, shops) => {
    let sorted = [...shops];
    switch (option) {
      case "Nearby":
        sorted.sort((a, b) => a.distance - b.distance);
        break;
      case "Low to High":
        sorted.sort((a, b) => a.discountPrice - b.discountPrice);
        break;
      case "High to Low":
        sorted.sort((a, b) => b.discountPrice - a.discountPrice);
        break;
      default:
        sorted.sort((a, b) => b.rating - a.rating);
    }
    setSortedOffers(sorted);
  };

  useEffect(() => {
    sortShops(sortOption, offers);
  }, [sortOption, offers]);

  const handleServiceChange = (newService) => {
    setService(newService);
    navigate(`/offer-discounts/${newService}`);
  };

  return (
    <div className="w-full max-w-7xl mx-auto px-6 py-10">
      {/* Hero Section */}
      <div
        ref={heroRef}
        className="relative w-full h-72 rounded-lg overflow-hidden shadow-lg mb-10 bg-gradient-to-r from-blue-500 via-purple-600 to-pink-500"
        style={{
          backgroundSize: "400% 400%",
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-black/40 to-black/80 mix-blend-multiply"></div>
        <div className="absolute bottom-8 left-8 text-white space-y-4">
          <h2
            ref={titleRef}
            className="text-3xl sm:text-5xl font-extrabold drop-shadow-lg"
          >
            Best {service} Offers
          </h2>
          <p
            ref={subtitleRef}
            className="text-lg sm:text-xl font-light drop-shadow-md"
          >
            Get Exclusive Discounts Now!
          </p>
        </div>
      </div>

      {/* Service & Sort Panel */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4 sm:gap-6">
        <h2 className="text-2xl sm:text-3xl font-bold">Available Offers</h2>

        <div className="flex flex-col sm:flex-row gap-4 sm:gap-6 items-start sm:items-center">
          {/* Service Switcher */}
          <select
            className="w-full sm:w-60 px-4 py-2 text-gray-800 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            value={service}
            onChange={(e) => handleServiceChange(e.target.value)}
          >
            {availableServices.map((s, index) => (
              <option key={index} value={s}>
                {s}
              </option>
            ))}
          </select>

          {/* Sort Dropdown */}
          <select
            className="w-full sm:w-60 px-4 py-2 text-gray-800 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
          >
            <option value="Ratings">Ratings</option>
            <option value="Nearby">Nearby</option>
            <option value="Low to High">Price: Low to High</option>
            <option value="High to Low">Price: High to Low</option>
          </select>
        </div>
      </div>

      {/* Shop Cards */}
      {isLoading ? (
        <div className="text-center text-gray-600 mt-20">
          <p>Loading Offers...</p>
        </div>
      ) : sortedOffers.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {sortedOffers.map((shop, index) => (
            <ServiceShop key={index} shop={shop} />
          ))}
        </div>
      ) : (
        <div className="text-center text-gray-600 mt-20">
          <p className="text-xl font-semibold">
            No Offers Available for {service}.
          </p>
        </div>
      )}
    </div>
  );
}

export default OfferDiscounts;
