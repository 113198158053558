import "./config";
import httpService from "./HttpService";

const requestOtp = (mobile) => {
  return httpService.post("/users/login/generateOtp", { mobile });
};

const verifyOtp = (mobile, otp) => {
  return httpService.post("/users/login/verifyOtp", { mobile, otp });
};

const login = (data) => {
  return httpService.post("users/login", data);
};

const signup = (data) => {
  return httpService.post("users/signup", data);
};

// Check if user is logged in
const isLoggedIn = () => {
  return !!localStorage.getItem("Groomsy Token");
};

// Get user details
const getUserDetails = () => {
  return httpService.get("users", {
    headers: {
      authorization: "Bearer " + localStorage.getItem("Groomsy Token"),
    },
  });
};

const updateUserDetails = (data) => {
  return httpService.put("users", data, {
    headers: { authorization: "Bearer " + localStorage.getItem("Groomsy Token") },
  });
};

// Logout user
const logout = () => {
  localStorage.removeItem("Groomsy Token");
};


const AuthService = {
  requestOtp,
  verifyOtp,
  login,
  signup,
  isLoggedIn,
  getUserDetails,
  updateUserDetails,
  logout,
};

export default AuthService;
