import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import AuthService from "../../services/AuthService";

const EditProfile = () => {
  const [profileData, setProfileData] = useState({
    age: "",
    gender: "",
    mobileNumber: "",
    userEmail: "",
    userId: "",
    userName: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await AuthService.getUserDetails();

        setProfileData({
          age: response.data.user.age,
          gender: response.data.user.gender,
          mobileNumber: response.data.user.mobileNumber,
          userEmail: response.data.user.userEmail,
          userId: response.data.user.userId,
          userName: response.data.user.userName,
        });
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchProfileData();
  }, []);

  const handleInputChange = (field, value) => {
    setProfileData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const toggleGender = (gender) => {
    setProfileData((prevData) => ({
      ...prevData,
      gender: gender,
    }));
  };


  const saveUserDetails = async () => {
    try {
      const response = await AuthService.updateUserDetails({
        userName: profileData.userName,
        userEmail: profileData.userEmail,
        age: profileData.age,
        gender: profileData.gender,
      });

      if (response.status === 200) {
        toast.success("Profile updated successfully!");
      } else {
        console.error("Error updating profile:", response);
      }
    } catch (error) {
      console.error("Error saving user details:", error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="w-full max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl m-4 p-6 bg-white shadow-lg rounded-lg">
        <h2 className="text-center text-base md:text-2xl font-bold mb-6">
          Edit Your Profile
        </h2>

        {/* Profile Image Upload */}
        {/*<div className="text-center mb-6">
          <div className="relative w-24 h-24 mx-auto rounded-full overflow-hidden border">
            <img
              src="https://via.placeholder.com/150"
              alt="Profile"
              className="w-full h-full object-cover"
            />
            <input
              type="file"
              onChange={() => {}}
              className="absolute inset-0 opacity-0 cursor-pointer"
            />
          </div>
        </div>*/}

        {/* Full Name */}
        <div className="mb-4">
          <label className="block text-sm text-black font-bold mb-2">
            Full Name
          </label>
          <input
            type="text"
            value={profileData.userName}
            onChange={(e) => handleInputChange("userName", e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Mobile Number */}
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">Mobile Number</label>
          <input
            type="tel"
            value={profileData.mobileNumber}
            onChange={(e) => handleInputChange("mobileNumber", e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Email 
        <div className="mb-4">
          <label className="block text-sm text-black font-bold mb-2">
            Email
          </label>
          <input
            type="email"
            value={profileData.userEmail}
            onChange={(e) => handleInputChange("userEmail", e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div> */}

        {/* Age */}
        <div className="mb-4">
          <label className="block text-sm text-black font-bold mb-2">
            Age
          </label>
          <input
            type="number"
            value={profileData.age}
            onChange={(e) => handleInputChange("age", e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Gender */}
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">Gender</label>
          <div className="flex space-x-4">
            <button
              onClick={() => toggleGender("male")}
              className={`flex-1 py-2 border rounded-md ${
                profileData.gender === "male"
                  ? "bg-[#1E85FF]/5 text-[#1E85FF]"
                  : "bg-[#FFFFFF] text-[#9D9D9D]"
              }`}
            >
              Men
            </button>
            <button
              onClick={() => toggleGender("women")}
              className={`flex-1 py-2 border rounded-md ${
                profileData.gender === "women"
                  ? "bg-[#1E85FF]/5 text-[#1E85FF]"
                  : "bg-[#FFFFFF] text-[#9D9D9D]"
              }`}
            >
              Women
            </button>
          </div>
        </div>

        

        {/* Buttons */}
        <div className="flex justify-between mt-6">
          <button
            onClick={() => {
              navigate("/");
            }}
            className="py-2 px-4 bg-gray-300 rounded-md hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={saveUserDetails}
            className="py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;