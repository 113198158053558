import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

// Image domain configuration
export const IMAGE_DOMAIN = 'https://static.groomsy.in';

/**
 * Formats image URLs to use the configured image domain
 * @param {string} imageUrl - The original image URL from backend
 * @returns {string} - The formatted image URL with proper domain
 */
export const getImageUrl = (imageUrl) => {
  if (!imageUrl) return '';
  
  // If the URL already has a protocol (http/https), return it as is
  if (imageUrl.startsWith('http://') || imageUrl.startsWith('https://')) {
    return imageUrl;
  }
  
  // If it's a relative URL, prepend the image domain
  return `${IMAGE_DOMAIN}${imageUrl.startsWith('/') ? '' : '/'}${imageUrl}`;
};
