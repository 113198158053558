import React, { useEffect, useState, useRef } from "react";
import "./shop-horizontal-card.scss";
import { IoLocationSharp } from "react-icons/io5";
import { dotSpinner } from "ldrs";
import { getImageUrl } from "../../services/config";

dotSpinner.register();

const ShopHorizontalCard = ({ shop }) => {
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [shopDetails, setShopDetails] = useState({
    shopName: "",
    shopCategory: "",
    rating: null,
    reviews: null,
    expertise: "",
    street: "",
    area: "",
    offers: [],
  });

  const tickerRef = useRef(null);

  useEffect(() => {
    if (shop) {
      const images = Array.isArray(shop.images) ? shop.images : [];
      if (images.length > 0) {
        setImage(images[0]);
      }
      setShopDetails({
        shopName: shop.shopName || "",
        shopCategory: shop.shopCategory || "",
        rating: shop.rating || null,
        reviews: shop.reviews || null,
        expertise: shop.expertise || "",
        area: shop.area || "",
        street: shop.street || "",
        offers: shop.offers || [
          { service: "Haircut", discount: "20% Off" },
          { service: "Facial", discount: "15% Off" },
          { service: "Manicure", discount: "10% Off" },
          { service: "Pedicure", discount: "15% Off" },
          { service: "Hair Color", discount: "25% Off" },
          { service: "Spa", discount: "30% Off" },
          { service: "Shaving", discount: "5% Off" },
        ],
      });
      setIsLoading(false);
    }
  }, [shop]);

  useEffect(() => {
    if (tickerRef.current) {
      const contentWidth = tickerRef.current.scrollWidth;
      const containerWidth = tickerRef.current.offsetWidth;

      const speed = Math.max(10, contentWidth / 50);
      tickerRef.current.style.animationDuration = `${speed}s`;
    }
  }, [shopDetails.offers]);

  return (
    <div className="relative w-[300px] h-[360px] border border-gray-200 rounded-xl shadow-sm overflow-hidden bg-white">
      {isLoading ? (
        <div className="flex justify-center items-center h-[168px] bg-gray-100">
          <l-dot-spinner size="40" speed="0.9" color="black"></l-dot-spinner>
        </div>
      ) : (
        <>
          {/* Shop Image */}
          <div className="w-full h-[168px] bg-gray-100 relative">
            {image ? (
              <img
                src={image.startsWith("http") ? image : getImageUrl(image)}
                alt="Shop"
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="w-full h-full bg-gray-200"></div>
            )}

            {/* Rating and Reviews Overlay */}
            {shopDetails.rating && shopDetails.reviews && (
              <div className="absolute top-0 right-0 bg-[rgba(0,0,0,0.5)] text-white text-xs p-2 rounded-bl-lg text-center">
                <span className="text-lg font-semibold">
                  {shopDetails.rating.toFixed(1)}
                </span>
                <span className="text-xs block">
                  {shopDetails.reviews} reviews
                </span>
              </div>
            )}

            {/* Category Overlay */}
            {shopDetails.shopCategory && (
              <div
                className={`absolute top-2 left-2 p-1 rounded-md text-xs font-bold ${
                  shopDetails.shopCategory.toLowerCase() === "unisex" || shopDetails.shopCategory.toLowerCase() === "uni-sex"
                    ? "!border border-[#8457f6] bg-clip-text text-transparent"
                    : shopDetails.shopCategory.toLowerCase() === "men"
                    ? "!border !border-blue-500 text-blue-500"
                    : shopDetails.shopCategory.toLowerCase() === "women"
                    ? "!border !border-pink-500 text-pink-500"
                    : "bg-gray-200 text-gray-500"
                }`}
                style={
                  shopDetails.shopCategory.toLowerCase() === "unisex" || shopDetails.shopCategory.toLowerCase() === "uni-sex"
                    ? {
                        background:
                          "linear-gradient(180deg, #0057FF 0%, #FF57EE 100%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }
                    : {}
                }
              >
                {shopDetails.shopCategory}
              </div>
            )}
          </div>

          {/* Shop Details */}
          <div className="px-4 py-3 flex flex-col justify-between">
            {/* Shop Name */}
            <span className="text-lg font-bold text-gray-900 truncate text-center pb-2">
              {shopDetails.shopName}
            </span>

            {/* Expertise Section */}
            <span className="text-gray-600 text-center text-sm line-clamp-2 flex-grow pb-2">
              {shopDetails.expertise}
            </span>

            {/* Address Section */}
            {(shopDetails.street || shopDetails.area) && (
              <div className="flex items-center justify-center text-gray-500 text-sm mt-2">
                <IoLocationSharp className="text-red-500 text-xl mr-1" />
                <span className="truncate">
                  {shopDetails.street}
                  {shopDetails.street && shopDetails.area ? ", " : ""}
                  {shopDetails.area}
                </span>
              </div>
            )}

            {/* Offers Section with Ticker */}
            {shopDetails.offers.length > 0 && (
              <div className="ticker-container pt-2">
                <div className="ticker-content" ref={tickerRef}>
                  {shopDetails.offers.map((offer, index) => (
                    <span key={index} className="inline-block mx-2">
                      {offer.service}:{" "}
                      <span className="font-bold text-[#C84C05]">
                        {offer.discount}
                      </span>
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ShopHorizontalCard;
