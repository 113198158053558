import React, { useEffect, useState } from "react";
import BookingModal from "../Modal/BookingModal.js";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import "./service-shops.scss";
import { getImageUrl } from "../../services/config";

const ServiceShop = (props) => {
  const navigate = useNavigate();

  const [serviceDetail, setServiceDetail] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [imageSrc, setImageSrc] = useState(
    "http://via.placeholder.com/150x150"
  );

  useEffect(() => {
    const parsedImages = Array.isArray(props?.shop?.images) ? props.shop.images : [];
    setImageSrc(parsedImages[0] || "http://via.placeholder.com/150x150");
    const token = localStorage.getItem("Groomsy Token");
    setIsLoggedIn(!!token);
  }, [props?.shop?.images]);

  const handleBookClick = () => {
    if (isLoggedIn) {
      setServiceDetail(props?.shop);
      setModalShow(true);
    } else {
      toast.error("Please log in to book an appointment.");
    }
  };

  const openMap = () => {
    const latLong = props?.shop?.latLong;
    if (latLong) {
      const [lat, lng] = latLong.split(",");
      if (!lat || !lng) {
        toast.error("Invalid location coordinates.");
        return;
      }
      window.open(`https://maps.google.com?q=${lat},${lng}`);
    } else {
      toast.error("Location details not available.");
    }
  };

  const calculateDiscount = () => {
    const { discountPrice, originalPrice } = props.shop;
    if (discountPrice && originalPrice && originalPrice > discountPrice) {
      return Math.round(
        ((originalPrice - discountPrice) / originalPrice) * 100
      );
    }
    return 0;
  };

  const discountPercentage = calculateDiscount();

  return (
    <div className="bg-white rounded-lg shadow-lg border border-gray-200 p-6 mb-8 hover:shadow-xl transform transition-all duration-300">
      <div className="flex justify-between items-start">
        <div className="flex-grow">
          {/* Shop Prices */}
          <div className="flex items-center mb-2 space-x-2">
            <h1 className="text-xl font-bold text-green-600">
              ₹{props?.shop?.discountPrice || props?.shop?.originalPrice || ""}
            </h1>

            {/* Original Price and Discount Percentage */}
            {props?.shop?.originalPrice &&
              props?.shop?.discountPrice &&
              props?.shop?.originalPrice > props?.shop?.discountPrice && (
                <div className="flex items-center space-x-2">
                  <span className="text-gray-400 line-through">
                    ₹{props?.shop?.originalPrice}
                  </span>

                  {/* Discount Badge */}
                  <span className="bg-green-500 text-white px-2 py-1 text-xs font-bold rounded animate-bounce">
                    {discountPercentage}% OFF
                  </span>
                </div>
              )}
          </div>

          {/* Shop Details */}
          <div
            className="text-lg font-bold text-gray-900 cursor-pointer hover:underline"
            onClick={() =>
              navigate(
                `/shop/${props.shop.shopName}?id=${props.shop.shopId}`
              )
            }
          >
            {props?.shop?.shopName || " - "}
          </div>

          {/* Category Badge */}
          {props?.shop?.shopCategory && (
            <span
              className={`mt-1 inline-block px-3 py-1 text-xs font-bold rounded-lg ${
                props?.shop?.shopCategory?.toLowerCase() === "uni-sex"
                  ? "bg-gradient-to-r from-blue-500 to-pink-500 text-white"
                  : props?.shop?.shopCategory?.toLowerCase() === "men"
                  ? "bg-blue-500 text-white"
                  : "bg-pink-500 text-white"
              }`}
            >
              {props?.shop?.shopCategory}
            </span>
          )}
        </div>

        <div className="flex-shrink-0 ml-4">
          <img
            className="w-16 h-16 rounded-lg object-cover"
            src={imageSrc.startsWith("http") ? imageSrc : getImageUrl(imageSrc)}
            alt={props?.shop?.shopName || "Shop Image"}
            onError={() =>
              setImageSrc("http://via.placeholder.com/150x150")
            }
          />
        </div>
      </div>

      <hr className="my-4" />

      {/* Shop Address */}
      <div className="text-sm text-gray-700 mb-4">
        <p>
          {props?.shop?.street}, {props?.shop?.area}
        </p>
        <p>{props?.shop?.landmark || "No landmark available"}</p>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-start space-x-4">
        <button
          onClick={openMap}
          className="text-blue-600 border border-blue-600 text-sm font-semibold py-2 px-4 rounded-lg hover:bg-blue-50 transition-all"
        >
          View Map
        </button>
        <button
          onClick={handleBookClick}
          className="bg-blue-600 text-white text-sm font-semibold py-2 px-4 rounded-lg hover:bg-blue-700 transition-all"
        >
          Book Now
        </button>
      </div>

      <BookingModal
        data={serviceDetail}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default ServiceShop;
