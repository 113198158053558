import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";
import "./Footer.scss";

const Footer = ({ facebookUrl, instagramUrl, twitterUrl, linkedinUrl }) => {
  const location = useLocation();
  const currentYear = new Date().getFullYear();
  const isHome = location.pathname === "/";

  const themeColor = "bg-[#2A2A2A] text-white";

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <footer className={`p-6 ${themeColor}`}>
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 px-0">
        {/* Services for Women */}
        <div>
          <h5 className="font-bold mb-3">SERVICES FOR WOMEN</h5>
          <ul className="p-0">
            {[
              "Haircut",
              "Hair Colouring",
              "Facial",
              "Massage",
              "Keratin Treatment",
              "Pedicure",
              "Hair Spa",
              "Make-Up",
              "Manicure",
              "Waxing",
            ].map((service) => (
              <li key={service} className="mb-2">
                <Link
                  to={`/service?name=${encodeURIComponent(service)}`}
                  className="no-underline hover:underline transition text-gray-400 hover:text-[#FF7EB3]"
                >
                  {service}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {/* Services for Men */}
        <div>
          <h5 className="font-bold mb-3">SERVICES FOR MEN</h5>
          <ul className="p-0">
            {[
              "Haircut",
              "Shaving",
              "Hair Colouring",
              "Head Massage",
              "Men Facial",
            ].map((service) => (
              <li key={service} className="mb-2">
                <Link
                  to={`/service?name=${encodeURIComponent(service)}`}
                  className="no-underline hover:underline transition text-gray-400 hover:text-[#FF7EB3]"
                >
                  {service}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {/* Company Section */}
        <div>
          <h5 className="font-bold mb-3">COMPANY</h5>
          <ul className="p-0">
            <li className="mb-2">
              <Link
                to="/about"
                className="no-underline hover:underline transition text-gray-400 hover:text-[#FF7EB3]"
              >
                About
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to="/terms"
                className="no-underline hover:underline transition text-gray-400 hover:text-[#FF7EB3]"
              >
                Terms & Conditions
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to="/privacy"
                className="no-underline hover:underline transition text-gray-400 hover:text-[#FF7EB3]"
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>

        {/* Contact Section */}
        <div>
          <h5 className="font-bold mb-3">CONTACT</h5>
          <ul className="p-0">
            <li className="mb-2">
              <Link
                to="/contactus"
                className="no-underline hover:underline transition text-gray-400 hover:text-[#FF7EB3]"
              >
                Contact Us
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to="/help"
                className="no-underline hover:underline transition text-gray-400 hover:text-[#FF7EB3]"
              >
                Help
              </Link>
            </li>
            <li className="text-sm text-gray-400">+91 81110 22400</li>
            <li className="text-sm text-gray-400">info@groomsy.in</li>
          </ul>
        </div>
      </div>

      {/* Follow Us Section */}
      <div className="container mx-auto mt-8 text-center">
        <h5 className="font-bold mb-2">Follow Us On</h5>
        <div className="flex justify-center space-x-4 mb-4">
          <a
            href={facebookUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-xl md:text-2xl text-[#1877F2] hover:text-[#FF7EB3]"
          >
            <FaFacebook />
          </a>
          <a
            href={instagramUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-xl md:text-2xl text-[#E1306C] hover:text-[#FF7EB3]"
          >
            <FaInstagram />
          </a>
          <a
            href={twitterUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-xl md:text-2xl text-[#1DA1F2] hover:text-[#FF7EB3]"
          >
            <FaTwitter />
          </a>
          <a
            href={linkedinUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-xl md:text-2xl text-[#0077B5] hover:text-[#FF7EB3]"
          >
            <FaLinkedin />
          </a>
        </div>
        <hr className="border-gray-600 mb-4" />
        <p className="text-sm text-gray-400">
          © {currentYear}, PURETHOUGHTS. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
