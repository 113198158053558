import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import AuthService from "../../services/AuthService";
import OtpModal from "./OtpModal";
import logo from "../../assets/groomsy-logo.png";
import { IoClose } from "react-icons/io5";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function LoginModal(props) {
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [inputError, setInputError] = useState("");
  const [isRequestingOtp, setIsRequestingOtp] = useState(false);
  const navigate = useNavigate();

  const validateMobileNumber = (mobile) => {
    if (!mobile) return "Mobile number is required";
    if (mobile.length !== 10 || isNaN(mobile))
      return "Mobile number should be 10 digits";
    return "";
  };

  const handleRequestOtp = async (mobile) => {
    try {
      setIsRequestingOtp(true);
      await AuthService.requestOtp(mobile);
      setMobileNumber(mobile);
      setOtpModalOpen(true);
      toast.success("OTP sent successfully!");
    } catch {
      toast.error("Failed to send OTP. Please try again.");
    } finally {
      setIsRequestingOtp(false);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const mobile = e.target.phone.value;
    const validationError = validateMobileNumber(mobile);

    if (validationError) {
      setInputError(validationError);
    } else {
      setInputError("");
      handleRequestOtp(mobile);
    }
  };

  const closeOtpModal = () => setOtpModalOpen(false);

  const submitOtp = async (otp) => {
    try {
      const response = await AuthService.verifyOtp(mobileNumber, otp);
      localStorage.setItem("Groomsy Token", response.data.token); 
      closeOtpModal();
      props.onHide(); 
      toast.success("OTP verified! Login successful.");
  
      if (response.data.isNewUser) {
        localStorage.setItem("isProfileComplete", "false"); 
      } else {
        const isProfileComplete = response.data.isProfileComplete; 
        localStorage.setItem("isProfileComplete", isProfileComplete ? "true" : "false");
      }
  
      window.location.reload(); 
    } catch {
      toast.error("Error verifying OTP. Please try again.");
    }
  };
  

  const resendOtp = async () => {
    try {
      await AuthService.requestOtp(mobileNumber);
      toast.success("OTP resent successfully!");
    } catch {
      toast.error("Failed to resend OTP. Please try again.");
    }
  };

  const handleInputChange = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

  return (
    <>
      <Modal {...props} size="md" centered>
        <Modal.Body className="p-0 flex justify-center items-center">
          <div className="w-full bg-white p-8 rounded-lg shadow-lg relative">
            <button
              onClick={props.onHide}
              className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 focus:outline-none"
            >
              <IoClose className="h-8 w-8 p-1 bg-gray-200 rounded-full" />
            </button>
            <div className="flex flex-col items-center">
              <img src={logo} alt="Groomsy Logo" className="w-32 mb-4" />
              <h2 className="text-2xl font-bold">Welcome,</h2>
              <p className="text-gray-600 text-center mb-6">Glad to see you!</p>

              <form onSubmit={onSubmit}>
                <input
                  name="phone"
                  placeholder="Mobile Number"
                  type="tel"
                  autoComplete="off"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  maxLength="10"
                  onInput={handleInputChange}
                  className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {inputError && (
                  <p className="text-red-500 text-sm mt-2">{inputError}</p>
                )}
                <button
                  type="submit"
                  className="mt-4 w-full bg-blue-600 text-white py-2 rounded-md"
                  disabled={isRequestingOtp}
                >
                  {isRequestingOtp ? "Requesting OTP..." : "Request OTP"}
                </button>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <OtpModal
        isOpen={otpModalOpen}
        onClose={closeOtpModal}
        mobileNumber={mobileNumber}
        onSubmit={submitOtp}
        resendOtp={resendOtp}
      />
    </>
  );
}

export default LoginModal;
