import * as React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { jwtDecode } from "jwt-decode";

import Footer from "./components/Footer/Footer";
import "./App.css";
import Home from "./pages/Home/Home";
import Shops from "./pages/Shops/Shops";
import Offers from "./pages/Offers/Offers";
import Signup from "./pages/Signup/Signup";
import ShopDetails from "./pages/Shops/Details/Details";
import BookingPage from "./pages/Booking/History/History";
import ServicesShopsList from "./pages/Services/List/List";
import EditProfile from "./pages/EditProfile/EditProfile";
import About from "./pages/About";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Help from "./pages/Help";
import SearchResultsPage from "./pages/SearchResults/SearchResultsPage";
import ContactUs from "./pages/ContactUs";
import ServiceSearchResults from "./pages/SearchResults/ServiceSearchResults";
import ShopSearchResults from "./pages/SearchResults/ShopSearchResults";
import CompleteProfile from "./components/CompleteProfile/CompleteProfile";
import MainLayout from "./components/layouts/MainLayout";
import ProfileReminderModal from "./components/Modal/ProfileReminderModal";
import AuthService from "./services/AuthService";
import OfferDiscounts from "./components/Offers/OfferDiscount";
import PromotionalBanner from "./components/PromotionalBanners/PromotionalBanners";

import { dotSpinner } from "ldrs";
dotSpinner.register();

function AppContents() {
  const [showProfileReminder, setShowProfileReminder] = React.useState(false);
  const location = useLocation();

  const isTokenExpired = () => {
    const token = localStorage.getItem("Groomsy Token");

    if (!token) {
      return true;
    }

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp && decodedToken.exp < currentTime) {
        return true;
      }

      return false;
    } catch (error) {
      console.error("Error decoding token:", error);
      return true;
    }
  };

  React.useEffect(() => {
    const fetchProfileStatus = async () => {
      if (localStorage.getItem("Groomsy Token")) {
        try {
          const response = await AuthService.getUserDetails();
          const user = response.data.user;

          const isProfileComplete =
            user.userName && user.age && user.gender;

          localStorage.setItem(
            "isProfileComplete",
            isProfileComplete ? "true" : "false"
          );

          const hasSeenModal = sessionStorage.getItem(
            "hasSeenCompleteProfileModal"
          );

          if (location.pathname === "/" && !isProfileComplete && !hasSeenModal) {
            setShowProfileReminder(true);
            sessionStorage.setItem("hasSeenCompleteProfileModal", "true"); 
          } else {
            setShowProfileReminder(false);
          }
        } catch (error) {
          console.error("Error fetching profile status:", error);
          // If there's an error (like token expired), clear the token
          if (error.response && error.response.status === 403) {
            localStorage.removeItem("Groomsy Token");
          }
        }
      } else {
        // If no token exists, ensure profile is marked as incomplete
        localStorage.setItem("isProfileComplete", "false");
      }
    };

    fetchProfileStatus();
  }, [location.pathname]);

  if (isTokenExpired() && localStorage.getItem("Groomsy Token")) {
    localStorage.removeItem("Groomsy Token");
    window.location.reload();
  }

  return (
    <>
      <React.Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <l-dot-spinner size="40" speed="0.9" color="black"></l-dot-spinner>
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="shops"
            element={
              <MainLayout>
                <Shops />
              </MainLayout>
            }
          />
          <Route
            path="offers"
            element={
              <MainLayout>
                <Offers />
              </MainLayout>
            }
          />
          <Route
            path="booking-history"
            element={
              <MainLayout>
                <BookingPage />
              </MainLayout>
            }
          />
          <Route
            path="/edit-profile"
            element={
              <MainLayout>
                <EditProfile />
              </MainLayout>
            }
          />
          <Route
            path="signup"
            element={
              <MainLayout>
                <Signup />
              </MainLayout>
            }
          />
          <Route
            path="shop/:shopName"
            element={
              <MainLayout>
                <ShopDetails />
              </MainLayout>
            }
          />
          <Route
            path="service"
            element={
              <MainLayout>
                <ServicesShopsList />
              </MainLayout>
            }
          />
          <Route
            path="/about"
            element={
              <MainLayout>
                <About />
              </MainLayout>
            }
          />
          <Route
            path="/terms"
            element={
              <MainLayout>
                <Terms />
              </MainLayout>
            }
          />
          <Route
            path="/privacy"
            element={
              <MainLayout>
                <Privacy />
              </MainLayout>
            }
          />
          <Route
            path="/help"
            element={
              <MainLayout>
                <Help />
              </MainLayout>
            }
          />
          <Route
            path="/contactus"
            element={
              <MainLayout>
                <ContactUs />
              </MainLayout>
            }
          />
          <Route
            path="/search-results"
            element={
              <MainLayout>
                <SearchResultsPage />
              </MainLayout>
            }
          />
          <Route
            path="/service-search-results"
            element={
              <MainLayout>
                <ServiceSearchResults />
              </MainLayout>
            }
          />
          <Route
            path="/shop-search-results"
            element={
              <MainLayout>
                <ShopSearchResults />
              </MainLayout>
            }
          />
          <Route
            path="/complete-profile"
            element={
              <MainLayout>
                <CompleteProfile />
              </MainLayout>
            }
          />
          <Route path="/" element={<PromotionalBanner />} />
          <Route
            path="/offer-discounts/:service"
            element={
              <MainLayout>
                <OfferDiscounts />
              </MainLayout>
            }
          />
        </Routes>
      </React.Suspense>

      {/* Profile Reminder Modal */}
      {showProfileReminder && (
        <ProfileReminderModal onClose={() => setShowProfileReminder(false)} />
      )}

      <Toaster position="top-center" reverseOrder={false} />
      <Footer
        facebookUrl="https://www.facebook.com/groomfully.groomfully"
        instagramUrl="https://www.instagram.com/groomsy.in"
      />
    </>
  );
}

function App() {
  return (
    <Router>
      <AppContents />
    </Router>
  );
}

export default App;
