import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useParams, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import BookingModal from "../../../components/Modal/BookingModal";
import "./Details.scss";
import HomeService from "../../../services/HomeService";
import RatingSummary from "../../../components/RatingSummary/RatingSummary";
import ReviewModal from "../../../components/Modal/ReviewModal";
import CustomerReviews from "../../../components/CustomerReviews/CustomerReviews";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FaUserCircle } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { getImageUrl } from "../../../services/config";

import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { dotSpinner } from "ldrs";
import toast from "react-hot-toast";

dotSpinner.register();

const ShopDetails = (props) => {
  let deviceType = "desktop";

  const [modalShow, setModalShow] = useState(false);
  const [shopDetail, setShopDetail] = useState();
  const [profilePic] = useState("");
  const [reviewModalShow, setReviewModalShow] = useState(false);
  const [serviceDetail, setServiceDetail] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentDay, setCurrentDay] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useParams();
  const [searchParams] = useSearchParams();

  const Ratings = [];
  const workingHours = [];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const shopId = searchParams.get("id");

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const mapCenter = shopDetail?.latLong
    ? {
        lat: parseFloat(shopDetail.latLong.split(",")[0]),
        lng: parseFloat(shopDetail.latLong.split(",")[1]),
      }
    : { lat: 28.6139, lng: 77.209 };

  useEffect(() => {
    const today = new Date();
    const istTime = today.toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
    const istDate = new Date(istTime);
    const dayIndex = istDate.getDay();
    setCurrentDay(dayIndex);

    const token = localStorage.getItem("Groomsy Token");
    setIsLoggedIn(!!token);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getShopDetails(shopId);
  }, [shopId]);

  const openMap = () => {
    if (shopDetail && shopDetail.latLong) {
      const [lat, lng] = shopDetail.latLong.split(",");
      window.open(`https://maps.google.com?q=${lat},${lng}`);
    }
  };

  const getShopDetails = (id) => {
    setIsLoading(true);
    HomeService.GetShopDetails(id)
      .then((response) => {
        try {
          const shopData = response?.data?.shop || {};
          const servicesData = response?.data?.services || [];

          if (shopData.images) {
            shopData.images = Array.isArray(shopData.images) ? shopData.images : [];
          }

          setShopDetail({
            shopName: shopData.shopName || "",
            shopCategory: shopData.shopCategory || "",
            services: servicesData || [],
            rating: shopData.rating || null,
            reviews: shopData.reviews || null,
            street: shopData.street || "",
            area: shopData.area || "",
            address: shopData.address || "",
            city: shopData.city || "",
            state: shopData.state || "",
            pincode: shopData.pincode || "",
            landmark: shopData.landmark || "",
            images: shopData.images || [],
            aboutUs: shopData.aboutUs || "",
            expertise: shopData.expertise || "",
            latLong: shopData.latLong || "",
            shopId: shopData.shopId || "",
          });
        } catch (error) {
          console.error("Error parsing JSON data:", error);
          setShopDetail({});
        }
      })
      .catch((error) => {
        console.error("Error fetching shop details:", error);
        setShopDetail({});
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const ArticleCard = ({ image, index }) => {
    return (
      <div className="relative w-full overflow-hidden pb-[75%] md:pb-[50%] lg:pb-[40%] max-h-[500px] lg:max-h-[600px] border border-gray-200 md:border-2 lg:border-2 rounded-md">
        {image.includes(".mp4") ? (
          <video
            className="absolute top-0 left-0 w-full h-full object-contain md:object-cover lg:object-contain"
            controls
            key={index}
            src={getImageUrl(image)}
            type="video/mp4"
            autoPlay
            loop
            muted
          />
        ) : (
          <img
            className="absolute top-0 left-0 w-full h-full object-contain md:object-cover lg:object-contain"
            src={getImageUrl(image)}
            alt={`Slide ${index}`}
            key={index}
          />
        )}
      </div>
    );
  };

  const CustomDot = ({ shopDetail, onClick, active, index }) => {
    const image = shopDetail.images[index];

    return (
      <button
        onClick={(e) => {
          onClick();
          e.preventDefault();
        }}
        className={classNames(
          "h-12 w-12 rounded overflow-hidden border-2 border-transparent",
          {
            "border-blue-500": active,
          }
        )}
        key={index}
      >
        {image.includes(".mp4") ? (
          <video
            className="h-full w-full object-cover"
            src={getImageUrl(image)}
            muted
            playsInline
            preload="metadata"
          />
        ) : (
          <img
            className="h-full w-full object-cover"
            src={getImageUrl(image)}
            alt="Thumbnail"
          />
        )}
      </button>
    );
  };

  const handleWriteReviewClick = () => {
    if (isLoggedIn) {
      setReviewModalShow(true);
    } else {
      toast.error("Please log in to write a review.");
    }
  };

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>{`Book ${shopDetail?.shopName} in ${shopDetail?.city} | Groomsy`}</title>
          <meta
            name="description"
            content={`Book an appointment at ${
              shopDetail?.shopName
            }, a top-rated ${shopDetail?.shopCategory} salon in ${
              shopDetail?.city
            }. Offering services like ${shopDetail?.services
              ?.map((service) => service.serviceName)
              .join(", ")}${
              shopDetail?.rating
                ? ` with a rating of ${shopDetail.rating}.`
                : ""
            }`}
          />
        </Helmet>
      </HelmetProvider>

      {/* Main content */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <l-dot-spinner size="40" speed="0.9" color="black"></l-dot-spinner>
        </div>
      ) : (
        shopDetail && (
          <div className="flex flex-col md:flex-row mt-4 p-6">
            {/* Left Side - Carousel and Shop Details */}
            <div className="w-full md:w-2/3 relative pr-4 mr-4">
              <Carousel
                showDots
                deviceType={deviceType}
                slidesToSlide={1}
                containerClass="carousel-with-custom-dots"
                responsive={responsive}
                partialVisible
                infinite
                customDot={<CustomDot shopDetail={shopDetail} />}
              >
                {shopDetail?.images?.map((image, index) => (
                  <ArticleCard key={index} image={image} />
                ))}
              </Carousel>
              {/* Rating Overlay on Carousel */}
              {shopDetail.rating && shopDetail.reviews && (
                <div className="absolute top-0 right-0 p-4 bg-black bg-opacity-50 text-white">
                  <span className="text-2xl font-bold">
                    {shopDetail?.rating}
                  </span>
                  <div className="text-sm">{shopDetail?.reviews} Reviews</div>
                </div>
              )}
              {/* Shop Name, Category and About Us */}
              <div className="md:px-4 py-4">
                <h1 className="text-2xl text-[#2A2A2A] font-extrabold">
                  {shopDetail?.shopName}
                </h1>

                <span
                  className={`px-2 py-1 rounded-md text-xs font-bold ${
                    shopDetail?.shopCategory?.toLowerCase() === "unisex" || shopDetail?.shopCategory?.toLowerCase() === "uni-sex"
                      ? "!border border-[#8457f6] bg-clip-text text-transparent"
                      : shopDetail?.shopCategory?.toLowerCase() === "men"
                      ? "!border !border-blue-500 text-blue-500"
                      : shopDetail?.shopCategory?.toLowerCase() === "women"
                      ? "!border !border-pink-500 text-pink-500"
                      : "bg-gray-200 text-gray-500"
                  }`}
                  style={
                    shopDetail?.shopCategory?.toLowerCase() === "unisex" || shopDetail?.shopCategory?.toLowerCase() === "uni-sex"
                      ? {
                          background:
                            "linear-gradient(180deg, #0057FF 0%, #FF57EE 100%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }
                      : {}
                  }
                >
                  {shopDetail?.shopCategory}
                </span>

                <div className="font-semibold text-[#6B6B6B] flex flex-wrap items-center">
                  <h6 className="m-0">{shopDetail?.address}</h6>
                  {shopDetail?.street && (
                    <>
                      <span className="mx-1">,</span>
                      <h6 className="m-0">{shopDetail?.street}</h6>
                    </>
                  )}
                  {shopDetail?.area && (
                    <>
                      <span className="mx-1">,</span>
                      <h6 className="m-0">{shopDetail?.area}</h6>
                    </>
                  )}
                  {shopDetail?.city && (
                    <>
                      <span className="mx-1">,</span>
                      <h6 className="m-0">{shopDetail?.city}</h6>
                    </>
                  )}
                  {shopDetail?.state && (
                    <>
                      <span className="mx-1">,</span>
                      <h6 className="m-0">
                        {shopDetail?.state} - {shopDetail?.pincode}
                      </h6>
                    </>
                  )}
                  {shopDetail?.landmark && (
                    <>
                      <span className="mx-1">,</span>
                      <h6 className="m-0">Landmark: {shopDetail?.landmark}</h6>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* Right-Side Content */}
            <div className="w-full md:w-1/4 p-3 bg-white z-0 border border-gray-200 rounded-xl shadow-md">
              <div className="p-3 rounded-xl bg-blue-50">
              {isLoaded ? (
                <GoogleMap
                  mapContainerStyle={{ width: "100%", height: "300px" }}
                  center={mapCenter}
                  zoom={13}
                >
                  <Marker position={mapCenter} />
                </GoogleMap>
              ) : (
                <div>Loading Map...</div>
              )}

                {/* Open Map Button */}
                <button
                  onClick={openMap}
                  className="rounded-lg mt-4 w-full text-white bg-[#1E85FF] p-2 text-sm hover:bg-[#1666CC] transition"
                >
                  Open in Google Maps →
                </button>

                {/* Address below the map */}
                <div className="text-center text-sm mt-4">
                  <IoLocationSharp className="text-red-500 text-lg inline-block" />
                  {shopDetail?.address}
                </div>
              </div>

              {/* About Us */}
              <div className="mt-3">
                <h4 className="text-lg font-bold">About Us</h4>{" "}
                {/* Reduced font size */}
                <p className="text-gray-600 text-sm">
                  {shopDetail?.aboutUs || "No description available."}
                </p>
              </div>

              {/* Expertise */}
              <div className="mt-3">
                <h4 className="text-lg font-bold">Expertise</h4>{" "}
                <p className="text-gray-600 text-sm">
                  {shopDetail?.expertise || "No expertise information available."}
                </p>
              </div>

              {/* Working Hours Section */}
              <div className="mt-3">
                <h4 className="text-lg font-extrabold">Working Hours</h4>{" "}
                <ul className="mt-2 p-0">
                  {workingHours.map((day, index) => (
                    <li
                      key={index}
                      className={`flex justify-between py-1 ${
                        index === currentDay
                          ? "font-bold text-black"
                          : "text-gray-600"
                      }`}
                    >
                      <span>{day.day}</span>
                      <span>{day.hours}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )
      )}

      {/* Services Section */}
      <div className="px-6 py-4 bg-[#F2F2F2] w-full">
        <h4 className="text-[#2A2A2A] mt-3 font-extrabold text-xl text-center">
          Our Services
        </h4>

        {/* Search Bar for Services */}
        <div className="flex justify-center">
          <input
            type="text"
            placeholder="Search for a service..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="mt-2 mb-4 p-2 border border-gray-300 rounded-lg w-full md:w-1/2 mx-auto"
          />
        </div>

        <div className="mt-4 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6">
          {shopDetail?.services
            ?.filter((service) =>
              service?.serviceName
                .toLowerCase()
                .includes(searchQuery.toLowerCase())
            )
            .map((service, index) => (
              <div
                key={index}
                className="text-[#2A2A2A] bg-white p-4 rounded-lg shadow-md flex flex-col justify-between"
              >
                <span className="font-bold capitalize">
                  {service?.serviceName}
                </span>
                <div className="flex flex-col mt-2">
                  <span className="text-[#9D9D9D] text-md">Fare:</span>
                  <div className="flex justify-between">
                    {service?.discountPrice &&
                    service?.originalPrice &&
                    service?.discountPrice !== service?.originalPrice ? (
                      <>
                        <span className="text-green-600 font-bold text-lg">
                          ₹ {service?.discountPrice}
                        </span>
                        <span className="text-gray-400 line-through">
                          ₹ {service?.originalPrice}
                        </span>
                      </>
                    ) : (
                      <span className="text-green-600 font-bold text-lg">
                        ₹ {service?.originalPrice || service?.discountPrice}
                      </span>
                    )}
                  </div>
                </div>

                <button
                  className="rounded-lg mt-4 bg-[#438179] font-bold p-2 text-[#2A2A2A] bg-opacity-10 border border-gray-300"
                  onClick={() => {
                    if (isLoggedIn) {
                      setModalShow(true);
                      setServiceDetail({
                        ...service,
                        shopId: shopDetail.shopId,
                      });
                    } else {
                      toast.error("Please log in to book an appointment.");
                    }
                  }}
                >
                  Book
                </button>
              </div>
            ))}
        </div>

        {/* Rating Summary */}
        <RatingSummary ratings={Ratings} />

        {/* Reviews Section */}
        <div className="mt-6">
          <div className="flex flex-col items-center bg-white shadow-md p-4">
            {profilePic ? (
              <img
                src={profilePic}
                alt="Profile"
                className="w-12 h-12 rounded-full mb-4"
              />
            ) : (
              <FaUserCircle className="w-12 h-12 rounded-full mb-4" />
            )}
            <h3 className="text-lg font-bold">Rate and Review</h3>
            <p className="text-sm text-gray-500 mb-4">
              Share your experience with us
            </p>
            <button
              onClick={handleWriteReviewClick}
              className="bg-yellow-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-yellow-600"
            >
              Write a Review
            </button>
          </div>
        </div>

        {/* Customer Reviews */}
        <CustomerReviews
          reviewsData={
            Array.isArray(shopDetail?.reviews) ? shopDetail.reviews : []
          }
        />
      </div>

      {/* Modals */}
      <BookingModal
        data={serviceDetail}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <ReviewModal
        show={reviewModalShow}
        onHide={() => setReviewModalShow(false)}
        shopName={shopDetail?.shopName}
        shopLocation={`${shopDetail?.city}, ${shopDetail?.state}`}
      />
    </div>
  );
};

export default ShopDetails;
