import React, { useState, useEffect, useRef } from "react";
import { FaCut } from "react-icons/fa";
import "./HorizontalScrollProgressBar.css";

const HorizontalScrollProgressBar = ({ progress, type = "default" }) => {
  const [direction, setDirection] = useState("forward");
  const prevProgressRef = useRef(progress);

  // Detect direction change
  useEffect(() => {
    if (progress > prevProgressRef.current) {
      setDirection("forward");
    } else if (progress < prevProgressRef.current) {
      setDirection("backward");
    }
    prevProgressRef.current = progress;
  }, [progress]);

  return (
    <div className="w-full px-4 md:px-8 mt-2">
      <div className="relative bg-white h-2 rounded-full">
        <div
          className={`${
            type === "salon" ? "bg-[#FF6B6B]" : type === "stylist" ? "bg-[hsl(307,68%,45%)]" : "bg-black"
          } h-full rounded-full transition-all duration-300`}
          style={{ width: `${progress}%` }}
        ></div>
        
        {/* Animated Icon */}
        <div 
          className="absolute top-1/2 transform -translate-y-1/2 transition-all duration-300 flex items-center justify-center"
          style={{ left: `${progress}%` }}
        >
          <FaCut 
            className={`${type === "salon" ? "text-[#FF6B6B]" : "text-[hsl(307,68%,45%)]"} h-6 w-6 drop-shadow-md scissors-animation ${direction === "backward" ? "scissors-backward" : ""}`} 
          />
        </div>
      </div>
    </div>
  );
};

export default HorizontalScrollProgressBar;
