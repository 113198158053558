import React, { useState, useEffect } from "react";

// Import men's trending styles
import artisticFade from "../../assets/trending/men/ArtisticFade.png";
import longLayers from "../../assets/trending/men/LongLayers.png";
import naturalCurls from "../../assets/trending/men/Naturalcurls.png";
import texturedCrop from "../../assets/trending/men/Texturedcrop.png";

// Import women's trending styles
import bluntLob from "../../assets/trending/women/TheBluntLob.png";
import hushCut from "../../assets/trending/women/TheHushCut.png";
import cherubBob from "../../assets/trending/women/ThecherubBob.png";
import voluminousBlowouts from "../../assets/trending/women/VoluminousBlowouts.png";

const menColor = "#3B82F6";
const womenColor = "#EC4899";

const TrendingStylesTimeline = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [trendingStyles, setTrendingStyles] = useState([]);

  useEffect(() => {
    const styles = [
      {
        image: artisticFade,
        title: "Artistic Fade",
        color: menColor,
        category: "men",
      },
      {
        image: bluntLob,
        title: "The Blunt Lob",
        color: womenColor,
        category: "women",
      },
      {
        image: naturalCurls,
        title: "Natural Curls",
        color: menColor,
        category: "men",
      },
      {
        image: hushCut,
        title: "The Hush Cut",
        color: womenColor,
        category: "women",
      },
      {
        image: longLayers,
        title: "Long Layers",
        color: menColor,
        category: "men",
      },
      {
        image: cherubBob,
        title: "The Cherub Bob",
        color: womenColor,
        category: "women",
      },
      {
        image: texturedCrop,
        title: "Textured Crop",
        color: menColor,
        category: "men",
      },
      {
        image: voluminousBlowouts,
        title: "Voluminous Blowouts",
        color: womenColor,
        category: "women",
      },
    ];

    setTrendingStyles(styles);
  }, []);

  return (
    <section className="relative py-16 bg-[#F2EFE7] text-gray-800 flex flex-col items-center overflow-hidden">
      {/* Section Title */}
      <h2 className="text-3xl font-bold text-gray-800 mb-10 text-center w-full px-4">
        Trending Styles
      </h2>

      {/* Desktop View (Expanding Cards) */}
      <div className="hidden md:flex w-full max-w-6xl gap-2 px-4 md:px-8 overflow-hidden">
        {trendingStyles.map((trend, index) => (
          <div
            key={index}
            className="group relative flex-[0.5] h-64 md:h-80 rounded-lg transition-all duration-500 cursor-pointer overflow-hidden hover:flex-[1.8]"
            style={{ backgroundColor: trend.color }}
          >
            {/* Default collapsed state: Show vertical text only */}
            <div className="absolute inset-0 flex items-center justify-center transition-all duration-500 group-hover:hidden">
              <h3 className="text-sm md:text-lg font-bold text-white transform -rotate-90 whitespace-nowrap">
                {trend.title}
              </h3>
            </div>

            {/* Expanded Content - Hidden initially, appears on hover */}
            <div className="absolute inset-0 flex flex-col items-center justify-center p-2 hidden group-hover:flex transition-all duration-500 z-10">
              {/* Image with Title Below */}
              <div className="w-full h-[90%] overflow-hidden rounded-lg">
                <img
                  src={trend.image}
                  alt={trend.title}
                  className="w-full h-full object-contain rounded-lg"
                />
              </div>

              {/* Title Only */}
              <div className="text-center text-white mt-1 w-full">
                <h3 className="text-base md:text-lg font-semibold truncate">
                  {trend.title}
                </h3>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Mobile View (Stacked Expanding Cards) */}
      <div className="flex flex-col gap-3 md:hidden w-full px-4">
        {trendingStyles.map((trend, index) => (
          <div
            key={index}
            onClick={() =>
              setExpandedIndex(expandedIndex === index ? null : index)
            }
            className={`rounded-lg transition-all duration-500 cursor-pointer overflow-hidden flex flex-col text-white ${
              expandedIndex === index
                ? "h-96"
                : "h-10 justify-center items-center"
            }`}
            style={{ backgroundColor: trend.color }}
          >
            {/* Title - Always Visible */}
            <h3
              className={`text-sm font-semibold px-2 ${
                expandedIndex === index
                  ? "text-lg pt-2 pb-1 text-center"
                  : "text-xs"
              }`}
            >
              {trend.title}
            </h3>

            {/* Expanded Content (Only visible when tapped/hovered) */}
            {expandedIndex === index && (
              <div className="px-4 pb-4 w-full flex-1 flex flex-col items-center justify-center">
                {/* Image only */}
                <div className="w-full h-full overflow-hidden rounded-lg">
                  <img
                    src={trend.image}
                    alt={trend.title}
                    className="w-full h-full object-contain rounded-lg"
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default TrendingStylesTimeline;
