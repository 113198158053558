import './config';
import httpService from './HttpService';

const GetShopsList = () => {
    return httpService.get("users/shops");
}

const GetShopDetails = (id) => {
    return httpService.get("users/shop/detail/" + id);
}

const GetServiceShopsList = (name) => {
    return httpService.get("users/service/" + name);
}

const bookAppointment = (data) => {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authorization': "Bearer " + localStorage.getItem('Groomsy Token')
        }
    };
    return httpService.post('users/appointment', data, requestOptions);
}


const HomeService = {
    GetShopsList,
    GetShopDetails,
    bookAppointment,
    GetServiceShopsList
};

export default HomeService;
