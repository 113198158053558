import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import "../../assets/css/datetime.css";
import toast from "react-hot-toast";
import LoginModal from "./LoginModal";
import HomeService from "../../services/HomeService";
import "./BookingModal.css";
import { IoClose } from "react-icons/io5";

const BookingModal = (props) => {
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false); // Profile reminder modal state

  const minDate = new Date();

  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  };

  const changeDate = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleTimeSlotChange = (e) => {
    setSelectedTimeSlot(e.target.value);
  };

  const checkProfileCompletion = () => {
    const isProfileComplete = localStorage.getItem("isProfileComplete");
    return isProfileComplete === "true";
  };

  const bookAppointment = async () => {
    if (isSubmitting) return;

    if (!selectedDate) {
      toast.error("Please select a date.");
      return;
    }

    if (!selectedTimeSlot) {
      toast.error("Please select a time slot.");
      return;
    }

    // Check if profile is complete before booking
    if (!checkProfileCompletion()) {
      setShowProfileModal(true); // Show profile reminder modal
      return;
    }

    setIsSubmitting(true);

    if (localStorage.getItem("Groomsy Token") != null) {
      let data = {
        category: "salon",
        serviceName: props.data.serviceName,
        serviceId: props.data.serviceId || "",
        originalPrice: props.data.originalPrice,
        discountPrice: props.data.discountPrice,
        appointmentDate: formatDate(selectedDate),
        timeSlot: selectedTimeSlot,
        shopId: props.data.shopId,
      };

      try {
        const response = await HomeService.bookAppointment(data);
        setIsSubmitting(false);

        if (response.status === 200) {
          toast.success("Appointment requested successfully!");
          props.onHide();
        } else {
          toast.error("Failed to book appointment. Please try again.");
        }
      } catch (error) {
        setIsSubmitting(false);
        toast.error("An error occurred while booking. Please try again later.");
        console.error("Error:", error);
      }
    } else {
      setIsSubmitting(false);
      setModalShow(true); // Show login modal if the user is not logged in
    }
  };

  return (
    <div>
      {props?.data && (
        <Modal {...props} centered className="custom-booking-modal">
          <Modal.Header className="border-0 flex justify-between items-center px-3 py-2">
            <h5 className="text-lg font-semibold pt-3">Details:</h5>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              onClick={props.onHide}
            >
              <IoClose className="h-8 w-8 p-1 bg-gray-200 rounded-full text-black" />
            </button>
          </Modal.Header>
          <Modal.Body className="px-3 py-2 md:px-6 lg:px-8">
            <div className="border rounded-lg px-2 pt-2 pb-1 bg-gray-100">
              <DatePickerComponent
                format="dd-MM-yyyy"
                min={minDate}
                value={selectedDate}
                onChange={changeDate}
                placeholder="DD/MM/YYYY"
                className="w-full border-none focus:outline-none bg-transparent"
                strictMode={true}
              />
            </div>

            {/* Time Slot Dropdown */}
            <div className="mt-4">
              <label htmlFor="timeSlot" className="block text-gray-700 font-semibold mb-2">
                Select Time Slot:
              </label>
              <select
                id="timeSlot"
                value={selectedTimeSlot}
                onChange={handleTimeSlotChange}
                className="w-full p-3 text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="" disabled>Select a time slot</option>
                <option value="Morning">Morning (9:00 AM - 12:00 PM)</option>
                <option value="Afternoon">Afternoon (12:00 PM - 4:00 PM)</option>
                <option value="Evening">Evening (4:00 PM - 8:00 PM)</option>
              </select>
            </div>

            <div className="flex justify-between items-center mb-4 pt-2">
              <div className="flex-1">
                <h4 className="text-lg font-bold text-gray-800">
                  {props.data.serviceName}
                </h4>
              </div>
              <div className="flex flex-col items-end">
                <div className="text-lg font-semibold text-green-600">
                  ₹{props.data.discountPrice}
                </div>
                <div className="text-sm text-gray-400 line-through">
                  ₹{props.data.originalPrice}
                </div>
              </div>
            </div>
            <Button
              onClick={bookAppointment}
              className="w-full bg-blue-600 text-white font-semibold text-center py-2 rounded-md"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Booking..." : "Book Appointment"}
            </Button>
          </Modal.Body>
        </Modal>
      )}
      <LoginModal show={modalShow} onHide={() => setModalShow(false)} />

      {/* Profile Completion Modal */}
      <Modal
        show={showProfileModal}
        onHide={() => setShowProfileModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Complete Your Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please complete your profile to proceed with booking.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowProfileModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => (window.location.href = "/complete-profile")}>
            Complete Profile
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BookingModal;
