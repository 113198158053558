import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

const ProfileReminderModal = ({ onClose }) => {
  return (
    <Modal show onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Complete Your Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Your profile is incomplete! Complete your registration to unlock full
          benefits of our site.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onClose();
            window.location.href = "/edit-profile"; 
          }}
        >
          Complete Now
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProfileReminderModal;
