import React, { useEffect, useState, useRef } from "react";
import { BiSearch, BiX } from "react-icons/bi";
import gsap from "gsap";
import Slider1 from "../../assets/img/slider1.webp";
import Slider2 from "../../assets/img/slider2.webp";
import Slider3 from "../../assets/img/slider3.webp";
import useSearch from "../../hooks/useSearch";

const HeroSearchBar = () => {
  const [textIndex, setTextIndex] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [Slider1, Slider2, Slider3];
  const texts = [
    "Discover and book salons & grooming professionals",
  ];

  const {
    query,
    handleQueryChange,
    clearSearch,
    shops,
    services,
    handleItemClick,
  } = useSearch();

  const imageRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
      gsap.fromTo(imageRef.current, { opacity: 0 }, { opacity: 1, duration: 1.5 });
    }, 4000);

    return () => clearInterval(slideInterval);
  }, []);

  useEffect(() => {
    const textInterval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
      gsap.fromTo(textRef.current, { opacity: 0, y: 10 }, { opacity: 1, y: 0, duration: 1 });
    }, 4000);

    return () => clearInterval(textInterval);
  }, []);

  return (
    <div className="relative w-full">
      {/* Hero Background */}
      <div className="relative w-full h-[300px] sm:h-[350px] md:h-[400px] lg:h-[500px] overflow-hidden">
        <img
          ref={imageRef}
          key={currentSlide}
          src={slides[currentSlide]}
          alt={`Slide ${currentSlide}`}
          className="absolute inset-0 w-full h-full object-cover object-center md:object-[0_20%] lg:object-center transition-opacity duration-1000 opacity-80"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-black/50 via-transparent to-black/70"></div>
      </div>

      {/* Text & Search Bar Section */}
      <div className="absolute top-0 left-0 right-0 z-10 flex flex-col justify-center items-center h-[300px] sm:h-[350px] md:h-[400px] lg:h-[500px] px-4 text-center">
        
        <h1
          ref={textRef}
          className="text-white text-lg sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-extrabold mb-4 transition-opacity duration-1000"
        >
          {texts[textIndex]}
        </h1>

        {/* Search Bar */}
        <div className="w-full max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-4xl mx-auto px-4 sm:px-6 md:px-8 relative">
          <div className="relative w-full flex items-center">
            <input
              type="text"
              value={query}
              onChange={handleQueryChange}
              placeholder="Search services or businesses"
              className="w-full px-3 py-2 sm:py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-lg transition duration-200 text-sm sm:text-base"
            />

            {query && (
              <button
                onClick={clearSearch}
                className="absolute right-12 text-gray-500 hover:text-red-500 transition-colors duration-150"
                aria-label="Clear Search"
              >
                <BiX className="text-lg sm:text-xl" />
              </button>
            )}

            <button
              onClick={() => handleItemClick(`/search-results?query=${query}`)}
              className="absolute right-4 bg-transparent text-gray-500 hover:text-blue-500 transition-colors duration-150"
              aria-label="Submit Search"
            >
              <BiSearch className="text-lg sm:text-xl" />
            </button>

            {/* Suggestions */}
            {query && (shops.length > 0 || services.length > 0) && (
              <div className="absolute top-full w-full bg-white mt-1 border border-gray-300 shadow-lg rounded-lg z-50">
                <div className="p-3">
                  <div className="flex flex-col md:flex-row">
                    {services.length > 0 && (
                      <div className="md:w-1/2">
                        <h3 className="text-lg font-extrabold mb-1">
                          Services
                        </h3>
                        <ul className="space-y-1 z-50">
                          {services.map((service, index) => (
                            <li
                              key={index}
                              onClick={() =>
                                handleItemClick(`/service?name=${service.serviceName}`)
                              }
                              className="cursor-pointer p-1 hover:bg-gray-100"
                            >
                              {service.serviceName}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {shops.length > 0 && (
                      <div className="md:w-1/2 md:pl-4 mt-2 md:mt-0">
                        <h3 className="text-lg font-extrabold mb-1">Shops</h3>
                        <ul className="space-y-1 z-50">
                          {shops.map((shop, index) => (
                            <li
                              key={index}
                              onClick={() =>
                                handleItemClick(`/shop/${shop.shopName}?id=${shop.shopId}`)
                              }
                              className="cursor-pointer p-1 hover:bg-gray-100"
                            >
                              {shop.shopName}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* No Results Message */}
            {query && shops.length === 0 && services.length === 0 && (
              <div className="absolute top-full w-full bg-white mt-1 border border-gray-300 shadow-lg rounded-lg z-50 p-3 text-gray-500">
                No services or shops found.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSearchBar;
