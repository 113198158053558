import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/AuthService";
import LoginModal from "../Modal/LoginModal";
import { FaUserCircle } from "react-icons/fa";

const MenuOverlay = ({ navbarOpen, setNavbarOpen, isHome }) => {
  const navigate = useNavigate();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userDetails, setUserDetails] = useState({
    userName: "",
    mobileNumber: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("Groomsy Token");
    setIsLoggedIn(!!token);

    if (token) {
      // Fetch user details
      AuthService.getUserDetails()
        .then((response) => {
          if (response.data && response.data.user) {
            setUserDetails({
              userName: response.data.user.userName,
              mobileNumber: response.data.user.mobileNumber,
            });
          }
        })
        .catch((err) => {
          console.error("An error occurred:", err);
        });
    }
  }, []);

  const handleLogout = () => {
    AuthService.logout();
    setNavbarOpen(false);
    setIsLoggedIn(false);
    navigate("/");
    window.location.reload();
  };

  const openLoginModal = () => {
    setShowLoginModal(true);
    setNavbarOpen(false);
  };

  const handleEditProfile = () => {
    setNavbarOpen(false);
    navigate("/edit-profile");
  };

  return (
    <div
      className={`fixed inset-y-0 right-0 z-[9999] flex flex-col w-[274px] max-w-sm shadow-lg transform ${
        navbarOpen ? "translate-x-0" : "translate-x-full"
      } transition-transform duration-300 ease-in-out`}
      style={{
        background: isHome ? "white" : "white",
      }}
    >
      {/* Top Navigation with Login and Close Button */}
      <div
        className="relative w-full h-14 shadow-sm flex justify-between items-center px-4"
        style={{
          background: isHome ? "white" : "white",
        }}
      >
        {!isLoggedIn && (
          <button
            onClick={() => openLoginModal()}
            className="bg-white border border-[#DDDDDD] rounded-full w-[73px] h-[34px] flex items-center justify-center"
          >
            <span className="text-sm font-bold text-[#2A2A2A]">Log in</span>
          </button>
        )}
        <button
          onClick={() => setNavbarOpen(false)}
          className="text-gray-700 focus:outline-none"
        >
          <svg
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>

      {/* Profile Section */}
      {isLoggedIn && userDetails && (
        <div className="relative flex flex-col items-center mt-10 mb-6">
          <div className="w-20 h-20 rounded-full">
            {userDetails.profileImage ? (
              <img
                className="w-20 h-20 rounded-full"
                src={userDetails.profileImage}
                alt="Profile"
              />
            ) : (
              <FaUserCircle className="w-20 h-20 text-black" />
            )}
          </div>
          <h3 className="mt-4 text-lg font-bold text-[#2A2A2A]">
            {userDetails.userName}
          </h3>
          <p className="text-sm text-black">{userDetails.mobileNumber}</p>
        </div>
      )}

      {/* Edit Profile Button (only if logged in) */}
      {isLoggedIn && (
        <button
          onClick={handleEditProfile}
          className="block w-[242px] h-10 mx-auto text-center rounded-md py-2 border transition duration-300"
          style={{
            background: isHome ? "#FFAEC020" : "#1E85FF0D",
            color: isHome ? "#FF758C" : "#1E85FF",
            borderColor: isHome ? "#FF758C55" : "#1E85FF33",
          }}
          onMouseEnter={(e) => {
            e.target.style.background = isHome ? "#FF758C" : "black";
            e.target.style.color = "white";
          }}
          onMouseLeave={(e) => {
            e.target.style.background = isHome ? "#FFAEC020" : "#1E85FF0D";
            e.target.style.color = isHome ? "#FF758C" : "#1E85FF";
          }}
        >
          Edit Your Profile
        </button>
      )}

      {/* Navigation Links */}
      <nav className="px-4 py-4 space-y-4 mt-8">
        {/*<Link
          to="/about"
          className="block text-[16px] text-decoration-none leading-[20px] font-semibold text-[#2A2A2A]"
          onClick={() => setNavbarOpen(false)}
        >
          About
        </Link>*/}
        {/*<Link
          to="/services"
          className="block text-[16px] leading-[20px] font-semibold text-[#2A2A2A]"
          onClick={() => setNavbarOpen(false)}
        >
          Services
        </Link>*/}
        {isLoggedIn && (
          <Link
            to="/booking-history"
            className="block text-[16px] leading-[20px] text-decoration-none font-semibold text-[#2A2A2A]"
            onClick={() => setNavbarOpen(false)}
          >
            Appointments
          </Link>
        )}
        {/*<Link
          to="/contactus"
          className="block text-[16px] leading-[20px] font-semibold text-decoration-none text-[#2A2A2A]"
          onClick={() => setNavbarOpen(false)}
        >
          Contact Us
        </Link>*/}

        {isLoggedIn && (
          <div
            onClick={handleLogout}
            className="block text-[16px] leading-[20px] font-semibold text-[#2A2A2A] cursor-pointer"
          >
            Sign out
          </div>
        )}
      </nav>

      {/* Login Modal */}
      <LoginModal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
      />
    </div>
  );
};

export default MenuOverlay;
