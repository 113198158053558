import React, { useEffect, useState, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ShopHorizontalCard from "../../components/shop-horizontal-card/shop-horizontal-card";
import "./Home.scss";
import { useNavigate } from "react-router-dom";
import HomeService from "../../services/HomeService";
import HeroSearchBar from "../../components/Search/HeroSearchBar";
import { PrevArrow } from "../../components/arrows/PrevArrow";
import { NextArrow } from "../../components/arrows/NextArrow";
import HorizontalScrollProgressBar from "../../components/progress-bar/HorizontalScrollProgressBar";
import ServiceListNavigation from "../../components/ServiceListNavigation/ServiceListNavigation";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FaArrowRight } from "react-icons/fa";
import Header from "../../components/Header/Header";
import PromotionalBanners from "../../components/PromotionalBanners/PromotionalBanners";
import StylistHorizontalCard from "../../components/StylistHorizontalCard/StylistHorizontalCard";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import TrendingStyles from "../../components/TrendingStyles/TrendingStyles";

const responsive = {
  superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
  desktop: { breakpoint: { max: 3000, min: 1594 }, items: 5 },
  Laptop: { breakpoint: { max: 1594, min: 1269 }, items: 4 },
  tablet: { breakpoint: { max: 1268, min: 943 }, items: 3 },
  mobile: { breakpoint: { max: 943, min: 646 }, items: 2.2 },
  mini_mobile: { breakpoint: { max: 646, min: 494 }, items: 1.5 },
  imobile: { breakpoint: { max: 493, min: 370 }, items: 1.14 },
  smobile: { breakpoint: { max: 369, min: 320 }, items: 1 },
};

const Home = () => {
  const [shopsList, setShopsList] = useState([]);
  const navRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const navigate = useNavigate();
  const [isDraggingSalon, setIsDraggingSalon] = useState(false);
  const [isDraggingStylist, setIsDraggingStylist] = useState(false);
  const [salonScrollProgress, setSalonScrollProgress] = useState(0);
  const [stylistScrollProgress, setStylistScrollProgress] = useState(0);

  let startXSalon = 0;
  let startXStylist = 0;

  useEffect(() => {
    getShopsList();
    checkArrows();
    const nav = navRef.current;
    if (nav) nav.addEventListener("scroll", checkArrows);
    window.addEventListener("resize", checkArrows);
    return () => {
      if (nav) nav.removeEventListener("scroll", checkArrows);
      window.removeEventListener("resize", checkArrows);
    };
  }, []);

  const getShopsList = () => {
    HomeService.GetShopsList().then((res) => {
      let data = res["data"];
      // Sort salons to prioritize those with images
      const sortedSalons = data.salons.sort((a, b) => {
        const aHasImages = Array.isArray(a.images) && a.images.length > 0;
        const bHasImages = Array.isArray(b.images) && b.images.length > 0;

        if (aHasImages && !bHasImages) return -1; // a comes first
        if (!aHasImages && bHasImages) return 1; // b comes first
        return 0; // keep original order if both have or don't have images
      });

      setShopsList(sortedSalons);
    });
  };

  const navigateListBusiness = () => {
    window.open("https://partners.groomsy.in", "_blank");
  };

  const scrollLeft = () =>
    navRef.current.scrollBy({ left: -200, behavior: "smooth" });
  const scrollRight = () =>
    navRef.current.scrollBy({ left: 200, behavior: "smooth" });

  const checkArrows = () => {
    const nav = navRef.current;
    if (nav) {
      const isOverflowing = nav.scrollWidth > nav.clientWidth;
      setShowLeftArrow(isOverflowing && nav.scrollLeft > 0);
      setShowRightArrow(
        isOverflowing && nav.scrollLeft < nav.scrollWidth - nav.clientWidth
      );
    }
  };

  const handleAfterChange = (currentSlide, section) => {
    const totalSlides = shopsList.length;
    let progress = (100 / totalSlides) * (currentSlide + 1);
    if (currentSlide === 0) progress = 1;
    if (currentSlide + 1 === totalSlides) progress = 100;

    if (section === "salon") {
      setSalonScrollProgress(progress);
    } else {
      setStylistScrollProgress(progress);
    }
  };

  return (
    <div className="bg-white">
      <HelmetProvider>
        <Helmet>
          <title>Home - Groomsy</title>
          <meta
            name="description"
            content="Discover exclusive salon and parlour services for men and women at Groomsy. Book your appointment online now!"
          />
        </Helmet>
      </HelmetProvider>

      {/* List your business */}
      <div className="flex lg:hidden justify-center items-center text-white bg-black py-3">
        <div className="container mx-auto flex justify-center items-center">
          <span
            onClick={navigateListBusiness}
            className="text-sm md:text-base hover:text-[#FF758C] font-bold cursor-pointer flex items-center"
          >
            List your business <FaArrowRight className="ml-1 h-3 w-3" />
          </span>
        </div>
      </div>
      <hr className="w-full border-t border-gray-300 m-0 block lg:hidden" />
      <Header isHome={true} />
      <HeroSearchBar />
      <div className="bg-[#C1D8C3]">
        <ServiceListNavigation />
      </div>

      <div className="bg-[#C1D8C3]">
        <PromotionalBanners />
      </div>

      <div
        style={{
          background: "linear-gradient(180deg, #FFFFFF 20%, #C7D9DD 100%)",
        }}
        className="relative px-2 py-8"
      >
        <div className="relative mb-4 px-4">
          <div className="flex justify-start md:justify-center">
            <h1 className="text-xl md:text-3xl font-bold">
              SALONS FOR YOU
            </h1>
          </div>
          <span
            onClick={() => navigate(`/shops`)}
            className="text-[#C84C05] text-sm font-bold cursor-pointer flex items-center absolute top-0 right-4 hover:underline hover:text-[#E05A0A] transition-colors duration-300"
          >
            View All <FaArrowRight className="ml-1 h-3 w-3" />
          </span>
        </div>
        <Carousel
          swipeable={true}
          draggable={true}
          responsive={responsive}
          ssr={false}
          infinite={false}
          autoPlay={false}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="all 0.5s ease-in-out"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={[]}
          dotListClass="custom-dot-list-style"
          itemClass="px-4 py-5 transform transition-transform duration-300 ease-in-out"
          arrows={true}
          renderButtonGroupOutside={true}
          customLeftArrow={<PrevArrow />}
          customRightArrow={<NextArrow />}
          beforeChange={() => setIsDraggingSalon(true)}
          afterChange={(nextSlide) => {
            setTimeout(() => setIsDraggingSalon(false), 100);
            handleAfterChange(nextSlide, "salon");
          }}
          slidesToSlide={1}
          partialVisible={true}
        >
          {shopsList.map((data, index) => (
            <div
              key={index}
              className="cursor-pointer select-none"
              style={{ userSelect: "none" }}
              onMouseDown={(e) => {
                startXSalon = e.clientX;
                setIsDraggingSalon(false);
              }}
              onMouseMove={(e) => {
                if (Math.abs(e.clientX - startXSalon) > 10) {
                  setIsDraggingSalon(true);
                }
              }}
              onMouseUp={(e) => {
                if (!isDraggingSalon) {
                  navigate(`/shop/${data?.shopName}?id=${data?.shopId}`);
                }
              }}
              onMouseLeave={() => setIsDraggingSalon(false)}
            >
              <ShopHorizontalCard shop={data} />
            </div>
          ))}
        </Carousel>

        <HorizontalScrollProgressBar
          progress={salonScrollProgress}
          type="salon"
        />
      </div>

      <TrendingStyles />

      <div
        style={{
          background: "linear-gradient(180deg, #F2EFE7 0%, #9ACBD0 100%)",
        }}
        className="relative px-2 py-8"
      >
        <div className="relative mb-4 px-4">
          <div className="flex justify-start md:justify-center">
            <h1 className="text-xl md:text-3xl font-bold">
              STYLISTS FOR YOU
            </h1>
          </div>
          <span
            onClick={() => navigate(`/shops`)}
            className="text-[#C84C05] text-sm font-bold cursor-pointer flex items-center absolute top-0 right-4 hover:underline hover:text-[#E05A0A] transition-colors duration-300"
          >
            View All <FaArrowRight className="ml-1 h-3 w-3" />
          </span>
        </div>
        <Carousel
          swipeable={true}
          draggable={true}
          responsive={responsive}
          ssr={false}
          infinite={false}
          autoPlay={false}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="all 0.5s ease-in-out"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={[]}
          dotListClass="custom-dot-list-style"
          itemClass="px-4 py-5 transform transition-transform duration-300 ease-in-out"
          arrows={true}
          renderButtonGroupOutside={true}
          customLeftArrow={<PrevArrow />}
          customRightArrow={<NextArrow />}
          beforeChange={() => setIsDraggingStylist(true)}
          afterChange={(nextSlide) => {
            setTimeout(() => setIsDraggingStylist(false), 100);
            handleAfterChange(nextSlide, "stylist");
          }}
          slidesToSlide={1}
          partialVisible={true}
        >
          {shopsList.map((data, index) => (
            <div
              key={index}
              className="cursor-pointer select-none"
              style={{ userSelect: "none" }}
              onMouseDown={(e) => {
                startXStylist = e.clientX;
                setIsDraggingStylist(false);
              }}
              onMouseMove={(e) => {
                if (Math.abs(e.clientX - startXStylist) > 10) {
                  setIsDraggingStylist(true);
                }
              }}
              onMouseUp={(e) => {
                if (!isDraggingStylist) {
                  navigate(`/shop/${data?.shopName}?id=${data?.shopId}`);
                }
              }}
              onMouseLeave={() => setIsDraggingStylist(false)}
            >
              <StylistHorizontalCard shop={data} />
            </div>
          ))}
        </Carousel>

        <HorizontalScrollProgressBar
          progress={stylistScrollProgress}
          type="stylist"
        />
      </div>

      <WhyChooseUs />
    </div>
  );
};

export default Home;
