import React, { useRef, useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import gsap from "gsap";

const services = [
  "HairCut",
  "Hair Coloring",
  "Facial",
  "Massage",
  "Keratin",
  "Pedicure",
  "Hair Spa",
  "Manicure",
  "Straightening",
  "Wax",
];

const ServiceListNavigation = () => {
  const navRef = useRef(null);
  const navigate = useNavigate();
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [activeService, setActiveService] = useState(null);
  const buttonRefs = useRef([]);

  useEffect(() => {
    gsap.fromTo(
      buttonRefs.current,
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, stagger: 0.1, duration: 0.8, ease: "power2.out" }
    );
  }, []);

  const checkArrows = () => {
    const nav = navRef.current;
    if (nav) {
      setShowLeftArrow(nav.scrollLeft > 0);
      setShowRightArrow(nav.scrollLeft < nav.scrollWidth - nav.clientWidth);
    }
  };

  const scrollLeft = () => {
    navRef.current.scrollBy({ left: -200, behavior: "smooth" });
  };

  const scrollRight = () => {
    navRef.current.scrollBy({ left: 200, behavior: "smooth" });
  };

  useEffect(() => {
    checkArrows();

    const nav = navRef.current;
    if (nav) {
      nav.addEventListener("scroll", checkArrows);
    }

    window.addEventListener("resize", checkArrows);

    return () => {
      if (nav) {
        nav.removeEventListener("scroll", checkArrows);
      }
      window.removeEventListener("resize", checkArrows);
    };
  }, []);

  return (
    <div className="relative w-full px-4 lg:px-10 flex items-center justify-between py-4">
      {/* Left Arrow */}
      {showLeftArrow && (
        <button
          onClick={scrollLeft}
          className="text-gray-600 bg-white p-2 rounded-full shadow-md hover:bg-gray-100 transition"
          aria-label="Scroll Left"
        >
          <FaChevronLeft />
        </button>
      )}

      {/* Service List Navigation */}
      <nav
        ref={navRef}
        className="flex flex-1 mx-4 overflow-x-auto no-scrollbar space-x-4 sm:space-x-6 md:space-x-8 lg:space-x-10"
      >
        {services.map((service, index) => (
          <button
            key={service}
            ref={(el) => (buttonRefs.current[index] = el)}
            className={`px-5 py-2 sm:px-6 sm:py-3 md:px-7 md:py-3 text-sm sm:text-base font-bold rounded-lg transition-all duration-300 whitespace-nowrap ${
              activeService === service
                ? "bg-[#E8649D] text-white shadow-lg scale-105"
                : "bg-white text-[#2A2A2A] hover:bg-[#FFAEC0] hover:text-[#155E95]"
            }`}
            onClick={() => {
              setActiveService(service);
              navigate(`/service?name=${encodeURIComponent(service)}`);
            }}
          >
            {service}
          </button>
        ))}
      </nav>

      {/* Right Arrow */}
      {showRightArrow && (
        <button
          onClick={scrollRight}
          className="text-gray-600 bg-white p-2 rounded-full shadow-md hover:bg-gray-100 transition"
          aria-label="Scroll Right"
        >
          <FaChevronRight />
        </button>
      )}
    </div>
  );
};

export default ServiceListNavigation;
