import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { FaSearch, FaCalendarCheck, FaStar, FaHome, FaSyncAlt, FaLightbulb } from "react-icons/fa";

const features = [
  { icon: <FaSearch />, title: "Effortless Discovery", description: "Browse a wide range of salons and independent stylists in your area." },
  { icon: <FaCalendarCheck />, title: "Hassle-Free Online Booking", description: "Schedule appointments instantly with real-time slot availability." },
  { icon: <FaStar />, title: "Verified Reviews & Ratings", description: "Genuine customer reviews help you choose the best salon or stylist." },
  { icon: <FaHome />, title: "At-Home Services", description: "Book a salon or stylist to perform services at home." },
  { icon: <FaSyncAlt />, title: "Smart Appointment Management", description: "Easily reschedule or cancel bookings without hassle." },
  { icon: <FaLightbulb />, title: "Grooming Tips & Trends", description: "Access expert advice on hair care, skincare, and grooming trends." },
];

const TheGroomsyExperience = () => {
  const itemsRef = useRef([]);

  useEffect(() => {
    itemsRef.current.forEach((item, index) => {
      gsap.fromTo(
        item,
        { opacity: 0, y: 30 },
        {
          opacity: 1,
          y: 0,
          delay: index * 0.2,
          ease: "power3.out",
          duration: 0.8,
        }
      );
    });
  }, []);

  return (
    <section className="relative py-6 bg-white text-gray-800 text-center px-4 md:px-0">
      <h2 className="text-2xl md:text-4xl font-bold mb-6 text-[#155E95]">The Groomsy Experience</h2>

      {/* Desktop & Tablet View (md+) */}
      <div className="hidden md:flex flex-col items-center gap-8 max-w-6xl mx-auto">
        {/* Top 3 Features */}
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 w-full text-center">
          {features.slice(0, 3).map((feature, index) => (
            <div
              key={index}
              ref={(el) => (itemsRef.current[index] = el)}
              className="flex flex-col items-center justify-center"
            >
              <div className="text-[#155E95] text-3xl md:text-5xl mb-2">
                {feature.icon}
              </div>
              <h3 className="text-lg md:text-xl font-semibold text-gray-900">{feature.title}</h3>
              <p className="text-sm md:text-base text-gray-600 max-w-xs">{feature.description}</p>
            </div>
          ))}
        </div>

        {/* Center Main Element */}
        <div className="text-[#155E95] text-xl md:text-2xl font-bold py-2 md:py-4">Our Platform</div>

        {/* Bottom 3 Features */}
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 w-full text-center">
          {features.slice(3, 6).map((feature, index) => (
            <div
              key={index + 3}
              ref={(el) => (itemsRef.current[index + 3] = el)}
              className="flex flex-col items-center justify-center"
            >
              <div className="text-[#155E95] text-3xl md:text-5xl mb-2">
                {feature.icon}
              </div>
              <h3 className="text-lg md:text-xl font-semibold text-gray-900">{feature.title}</h3>
              <p className="text-sm md:text-base text-gray-600 max-w-xs">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Mobile View (sm) */}
      <div className="flex flex-col items-center gap-4 md:hidden w-full">
        {/* Top 2 Features */}
        <div className="grid grid-cols-2 gap-4 w-full">
          {features.slice(0, 2).map((feature, index) => (
            <div
              key={index}
              ref={(el) => (itemsRef.current[index] = el)}
              className="flex flex-col items-center justify-center"
            >
              <div className="text-[#155E95] text-2xl mb-2">
                {feature.icon}
              </div>
              <h3 className="text-sm font-semibold text-gray-900">{feature.title}</h3>
              <p className="text-xs text-gray-600 max-w-[90%]">{feature.description}</p>
            </div>
          ))}
        </div>

        {/* Single Feature Below */}
        <div className="w-full text-center">
          {features.slice(2, 3).map((feature, index) => (
            <div
              key={index + 2}
              ref={(el) => (itemsRef.current[index + 2] = el)}
              className="flex flex-col items-center justify-center"
            >
              <div className="text-[#155E95] text-2xl mb-2">
                {feature.icon}
              </div>
              <h3 className="text-sm font-semibold text-gray-900">{feature.title}</h3>
              <p className="text-xs text-gray-600 max-w-[90%]">{feature.description}</p>
            </div>
          ))}
        </div>

        {/* Center Main Element */}
        <div className="text-[#155E95] text-lg font-bold py-2">Our Platform</div>

        {/* Single Feature Below Center */}
        <div className="w-full text-center">
          {features.slice(3, 4).map((feature, index) => (
            <div
              key={index + 3}
              ref={(el) => (itemsRef.current[index + 3] = el)}
              className="flex flex-col items-center justify-center"
            >
              <div className="text-[#155E95] text-2xl mb-2">
                {feature.icon}
              </div>
              <h3 className="text-sm font-semibold text-gray-900">{feature.title}</h3>
              <p className="text-xs text-gray-600 max-w-[90%]">{feature.description}</p>
            </div>
          ))}
        </div>

        {/* Bottom 2 Features */}
        <div className="grid grid-cols-2 gap-4 w-full">
          {features.slice(4, 6).map((feature, index) => (
            <div
              key={index + 4}
              ref={(el) => (itemsRef.current[index + 4] = el)}
              className="flex flex-col items-center justify-center"
            >
              <div className="text-[#155E95] text-2xl mb-2">
                {feature.icon}
              </div>
              <h3 className="text-sm font-semibold text-gray-900">{feature.title}</h3>
              <p className="text-xs text-gray-600 max-w-[90%]">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TheGroomsyExperience;
