import React, { useEffect, useState } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import "react-step-progress-bar/styles.css";
import LoginModal from "../../../components/Modal/LoginModal";
import ReviewModal from "../../../components/Modal/ReviewModal";
import { dotSpinner } from 'ldrs';

dotSpinner.register(); 

export default function BookingPage() {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [reviewModalShow, setReviewModalShow] = useState(false);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [appointments, setAppointments] = useState({
    pending: [],
    accepted: [],
    rejected: [],
    completed: [],
  });
  const [activeTab, setActiveTab] = useState("pending");
  const [isTokenAvailable, setIsTokenAvailable] = useState(false);

  const handleWriteReviewClick = () => {
    setReviewModalShow(true);
  };

  useEffect(() => {
    const token = localStorage.getItem("Groomsy Token");
    setIsTokenAvailable(!!token);

    if (token) {
      const fetchData = async () => {
        let headers = {
          authorization: "Bearer " + token,
        };

        try {
          const response = await fetch(
            "https://api.groomsy.in/users/appointments",
            { headers }
          );
          const result = await response.json();
          setIsLoaded(true);

          const fetchedAppointments = result.appointments.reduce(
            (acc, appointment) => {
              acc[appointment.appointmentStatus] = acc[
                appointment.appointmentStatus
              ]
                ? [...acc[appointment.appointmentStatus], appointment]
                : [appointment];
              return acc;
            },
            {}
          );

          const sortAppointments = (appointments) => {
            return appointments.sort((a, b) => {
              const dateA = new Date(`${a.appointmentDate}T${a.appointmentTime || "00:00:00"}`);
              const dateB = new Date(`${b.appointmentDate}T${b.appointmentTime || "00:00:00"}`);
          
              if (isNaN(dateA) || isNaN(dateB)) {
                console.warn("Invalid date detected:", a.appointmentDate, b.appointmentDate);
                return 0; 
              }
              return dateB - dateA;
            });
          };

          const finalAppointments = {
            pending: sortAppointments(fetchedAppointments.pending || []),
            accepted: sortAppointments(fetchedAppointments.accepted || []),
            rejected: sortAppointments(fetchedAppointments.rejected || []),
            completed: sortAppointments(fetchedAppointments.completed || []),
          };

          setAppointments(finalAppointments);
        } catch (error) {
          setIsLoaded(true);
          setError(error);
          setAppointments({
            pending: [],
            accepted: [],
            rejected: [],
            completed: [],
          });
        }
      };

      fetchData();
    }
  }, [activeTab]);

  if (!isTokenAvailable) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <p className="text-gray-500 mb-4">
            Please log in to view your appointments.
          </p>
          <button
            onClick={() => setModalShow(true)}
            className="bg-black text-white px-4 py-2 rounded-lg"
          >
            Login
          </button>
        </div>
        <LoginModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          onLoginSuccess={() => {
            setIsTokenAvailable(true);
            setModalShow(false);
            navigate("/booking-history");
          }}
        />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <div className="flex justify-center items-center h-64">
        <l-dot-spinner size="40" speed="0.9" color="black"></l-dot-spinner> 
      </div>
    );
  } else {
    return (
      <div className="min-h-screen bg-gray-100">
        <div className="mx-auto max-w-xl md:max-w-7xl">
          <div className="w-full">
            <span className="text-lg block sm:text-xl md:text-2xl lg:text-3xl font-bold text-left md:text-center p-[16px]">
              Appointments
            </span>
            <div className="border-b-2 border-gray-200 w-full"></div>
          </div>
        </div>

        <Container className="mx-auto max-w-xl !p-[16px]">
          {/* Tabs for appointment status */}
          <div className="border-b mb-4">
            <div className="flex sm:grid sm:grid-cols-4">
              {["pending", "accepted", "rejected", "completed"].map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`text-sm sm:text-base md:text-lg px-2 py-2 border-b-2 capitalize whitespace-nowrap w-full text-center ${activeTab === tab
                      ? "border-black text-black"
                      : "border-transparent text-gray-500"
                    } focus:outline-none`}
                >
                  {tab}
                </button>
              ))}
            </div>
          </div>

          {/* Display Appointments */}
          <div className="appointments-list space-y-4 rounded-xl">
            {appointments[activeTab]?.length > 0 ? (
              appointments[activeTab].map((appointment, index) => (
                <Card
                  key={index}
                  className="bg-white rounded-lg shadow-md border"
                >
                  <CardBody>
                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center relative">
                      <span className="text-base font-bold text-[#1e85ff]">
                        {appointment.serviceName}
                      </span>
                      <div className="flex items-center mt-1.5 sm:mt-0">
                        <span
                          className={`px-[12.5px] py-1 rounded-lg  text-gray-600 capitalize text-sm font-semibold ${appointment.appointmentStatus === "pending"
                              ? "text-[#2A2A2A] bg-gray-200"
                              : appointment.appointmentStatus === "accepted"
                                ? "text-green-500 bg-green-500/10"
                                : appointment.appointmentStatus === "rejected"
                                  ? "text-red-500 bg-red-500/10"
                                  : "text-gray-500 bg-gray-500/10"
                            }`}
                        >
                          {appointment.appointmentStatus}
                        </span>
                      </div>
                    </div>

                    {/* Divider */}
                    <div className="my-[14px] h-px bg-gray-200"></div>

                    {/* Date & Time */}
                    <div className="flex justify-between items-start sm:items-center">
                      <div className="flex flex-col">
                        <span className="text-sm font-bold text-gray-400">
                          Time
                        </span>
                        <span className="text-sm font-bold text-gray-900 pt-[6px] ">
                          {appointment.appointmentTime || "N/A"}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-sm font-bold text-gray-400">
                          Date
                        </span>
                        <span className="text-sm font-bold text-gray-900 pt-[6px] ">
                          {appointment.appointmentDate || "N/A"}
                        </span>
                      </div>
                    </div>

                    {/* Divider */}
                    <div className="my-[14px] h-px bg-gray-200"></div>

                    {/* Salon Details */}
                    <div className="space-y-2">
                      <div className="flex items-center">
                        <span className="text-base font-bold text-gray-900">
                          {appointment.shopName || " - "}
                        </span>
                        {appointment.shopType ? (
                          <span
                            className={`ml-2 flex justify-center items-center p-1 text-xs font-bold rounded-md ${appointment.shopType === "Uni-Sex"
                                ? "!border border-[#8457f6] bg-clip-text text-transparent"
                                : appointment.shopType === "Men"
                                  ? "!border !border-blue-500 text-blue-500"
                                  : appointment.shopType === "Women"
                                    ? "!border !border-pink-500 text-pink-500"
                                    : "bg-gray-200 text-gray-500"
                              }`}
                            style={
                              appointment.shopType === "Uni-Sex"
                                ? {
                                  background:
                                    "linear-gradient(180deg, #0057FF 0%, #FF57EE 100%)",
                                  WebkitBackgroundClip: "text",
                                  WebkitTextFillColor: "transparent",
                                }
                                : {}
                            }
                          >
                            {appointment.shopType}
                          </span>
                        ) : null}
                      </div>
                      <div className="text-gray-600 text-sm font-semibold">
                        <p>{appointment.address || "-"}</p>
                      </div>
                    </div>

                    {/* View Map Button */}
                    <div className="flex justify-center mt-4">
                      <button className="flex items-center bg-blue-50 border border-blue-200 text-blue-600 text-sm font-bold py-2 px-24 rounded-lg whitespace-nowrap">
                        View Map
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          className="w-4 h-4 ml-2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4 12h16m-6-6l6 6-6 6"
                          />
                        </svg>
                      </button>
                    </div>

                    {appointment.appointmentStatus === "completed" && (
                      <div className="mt-4">
                        <div className="flex items-center justify-between">
                          <div className="text-lg font-semibold text-gray-900">
                            ₹{appointment.discountPrice || appointment.originalPrice}
                          </div>
                          <button
                            onClick={handleWriteReviewClick}
                            className="text-blue-600 text-sm font-semibold border-2 border-blue-200 bg-blue-50 rounded-md p-1 cursor-pointer"
                          >
                            Write Review
                          </button>
                        </div>
                      </div>
                    )}
                  </CardBody>
                </Card>
              ))
            ) : (
              <p className="text-center text-gray-500">No appointments found</p>
            )}
          </div>
        </Container>
        <ReviewModal
          show={reviewModalShow}
          onHide={() => setReviewModalShow(false)}
        />
      </div>
    );
  }
}
