import React, { useState, useEffect, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import { useNavigate } from "react-router-dom";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import men_haircut from "../../assets/services/men_haircut.png";
import styling from "../../assets/services/styling.png";
import colouring from "../../assets/services/colouring.png";
import facial from "../../assets/services/facial.png";
import hairspa from "../../assets/services/hairspa.png";
import hair_straight from "../../assets/services/hair_straight.png";
import highlight from "../../assets/services/highlight.png";
import women_hair from "../../assets/services/women_hair.png";
import w_lowlight from "../../assets/services/w_lowlight.png";
import root_touch from "../../assets/services/root_touch.png";
import global_colour from "../../assets/services/global_colour.png";
import keratin from "../../assets/services/keratin.png";
import smoothening from "../../assets/services/smoothening.png";
import preming from "../../assets/services/preming.png";
import hair_extension from "../../assets/services/hair_extension.png";
import clean_up from "../../assets/services/clean_up.png";
import bleaching from "../../assets/services/bleaching.png";
import detanning from "../../assets/services/detanning.png";

// Add CSS for mobile layout rearrangement
const mobileStyles = `
  @media (max-width: 767px) {
    .promotional-banner {
      display: flex;
      flex-direction: column;
    }
    
    .promotional-banner .banner-image {
      order: 1;
      margin-bottom: 1rem;
    }
    
    .promotional-banner .banner-content {
      order: 2;
    }
    
    .promotional-banner .banner-button {
      order: 3;
    }
  }
`;

const banners = [
  {
    title: "Haircut",
    offer: "Flat 30% Off!",
    description: "Get a professional haircut with a modern style.",
    icon: (
      <img
        src={men_haircut}
        alt="Men Haircut"
        className="w-36 h-36 inline-block"
        loading="lazy"
      />
    ),
    bg: "bg-gradient-to-r from-blue-50 to-white",
    gender: "men",
  },
  {
    title: "Haircut",
    offer: "Flat 30% Off!",
    description: "Get a professional haircut with a modern style.",
    icon: (
      <img
        src={women_hair}
        alt="Women Haircut"
        className="w-36 h-36 inline-block"
        loading="lazy"
      />
    ),
    bg: "bg-gradient-to-r from-pink-50 to-white",
    gender: "women",
  },
  {
    title: "Hair Styling",
    offer: "Flat 25% Off!",
    description: "Expert styling for all your special occasions.",
    icon: (
      <img
        src={styling}
        alt="Women Hair Styling"
        className="w-36 h-36 inline-block"
        loading="lazy"
      />
    ),
    bg: "bg-gradient-to-r from-pink-50 to-white",
    gender: "women",
  },
  {
    title: "Hair Coloring",
    offer: "Flat 40% Off!",
    description: "Premium hair coloring by expert stylists.",
    icon: (
      <img
        src={colouring}
        alt="Women Hair Colouring"
        className="w-36 h-36 inline-block"
        loading="lazy"
      />
    ),
    bg: "bg-gradient-to-r from-pink-50 to-white",
    gender: "women",
  },
  {
    title: "Highlights",
    offer: "Flat 35% Off!",
    description: "Get stunning highlights for a trendy look.",
    icon: (
      <img
        src={highlight}
        alt="Highlights"
        className="w-36 h-36 inline-block"
        loading="lazy"
      />
    ),
    bg: "bg-gradient-to-r from-pink-50 to-white",
    gender: "women",
  },
  {
    title: "Lowlights",
    offer: "Flat 30% Off!",
    description: "Add dimension with expert lowlighting.",
    icon: (
      <img
        src={w_lowlight}
        alt="Lowlights"
        className="w-36 h-36 inline-block"
        loading="lazy"
      />
    ),
    bg: "bg-gradient-to-r from-pink-50 to-white",
    gender: "women",
  },
  {
    title: "Root Touch-up",
    offer: "Flat 25% Off!",
    description: "Quick and precise root touch-up service.",
    icon: (
      <img
        src={root_touch}
        alt="Root Touch-up"
        className="w-36 h-36 inline-block"
        loading="lazy"
      />
    ),
    bg: "bg-gradient-to-r from-pink-50 to-white",
    gender: "women",
  },
  {
    title: "Global Hair Coloring",
    offer: "Flat 35% Off!",
    description: "Complete hair color transformation.",
    icon: (
      <img
        src={global_colour}
        alt="Women Hair Colouring"
        className="w-36 h-36 inline-block"
        loading="lazy"
      />
    ),
    bg: "bg-gradient-to-r from-pink-50 to-white",
    gender: "women",
  },
  {
    title: "Keratin Treatment",
    offer: "Flat 40% Off!",
    description: "Get silky, smooth hair with keratin.",
    icon: (
      <img
        src={keratin}
        alt="Women Keratin Treatment"
        className="w-36 h-36 inline-block"
        loading="lazy"
      />
    ),
    bg: "bg-gradient-to-r from-pink-50 to-white",
    gender: "women",
  },
  {
    title: "Hair Smoothening",
    offer: "Flat 30% Off!",
    description: "Frizz-free, silky hair with smoothening.",
    icon: (
      <img
        src={smoothening}
        alt="Women Hair Smoothening"
        className="w-36 h-36 inline-block"
        loading="lazy"
      />
    ),
    bg: "bg-gradient-to-r from-pink-50 to-white",
    gender: "women",
  },
  {
    title: "Hair Straightening",
    offer: "Flat 35% Off!",
    description: "Sleek, straight hair with lasting results.",
    icon: (
      <img
        src={hair_straight}
        alt="Women Hair Straightening"
        className="w-36 h-36 inline-block"
        loading="lazy"
      />
    ),
    bg: "bg-gradient-to-r from-pink-50 to-white",
    gender: "women",
  },
  {
    title: "Hair Perming",
    offer: "Flat 30% Off!",
    description: "Get natural, bouncy curls with perming.",
    icon: (
      <img
        src={preming}
        alt="Women Hair Perming"
        className="w-36 h-36 inline-block"
        loading="lazy"
      />
    ),
    bg: "bg-gradient-to-r from-pink-50 to-white",
    gender: "women",
  },
  {
    title: "Hair Spa",
    offer: "Flat 35% Off!",
    description: "Relaxing spa treatment for your hair.",
    icon: (
      <img
        src={hairspa}
        alt="Hair Spa"
        className="w-36 h-36 inline-block"
        loading="lazy"
      />
    ),
    bg: "bg-gradient-to-r from-pink-50 to-white",
    gender: "women",
  },
  {
    title: "Hair Extensions",
    offer: "Flat 25% Off!",
    description: "Add length and volume to your hair.",
    icon: (
      <img
        src={hair_extension}
        alt="Women Hair Extensions"
        className="w-36 h-36 inline-block"
        loading="lazy"
      />
    ),
    bg: "bg-gradient-to-r from-pink-50 to-white",
    gender: "women",
  },

  // Skin Care Services
  {
    title: "Facials",
    offer: "Flat 30% Off!",
    description: "Rejuvenating facials for glowing skin.",
    icon: (
      <img
        src={facial}
        alt="Facial"
        className="w-36 h-36 inline-block"
        loading="lazy"
      />
    ),
    bg: "bg-gradient-to-r from-pink-50 to-white",
    gender: "women",
  },
  {
    title: "Clean-ups",
    offer: "Flat 20% Off!",
    description: "Deep cleansing for refreshed skin.",
    icon: (
      <img
        src={clean_up}
        alt="Clean-ups"
        className="w-36 h-36 inline-block"
        loading="lazy"
      />
    ),
    bg: "bg-gradient-to-r from-pink-50 to-white",
    gender: "women",
  },
  {
    title: "Bleaching",
    offer: "Flat 25% Off!",
    description: "Expert bleaching for brighter skin.",
    icon: (
      <img
        src={bleaching}
        alt="Bleaching"
        className="w-36 h-36 inline-block"
        loading="lazy"
      />
    ),
    bg: "bg-gradient-to-r from-pink-50 to-white",
    gender: "women",
  },
  {
    title: "Detanning",
    offer: "Flat 30% Off!",
    description: "Remove tan for glowing skin instantly.",
    icon: (
      <img
        src={detanning}
        alt="Detanning"
        className="w-36 h-36 inline-block"
        loading="lazy"
      />
    ),
    bg: "bg-gradient-to-r from-pink-50 to-white",
    gender: "women",
  },
];

function PromotionalBanner() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const bannersPerPage = window.innerWidth >= 1024 ? 2 : 1;
  const totalPages = Math.ceil(banners.length / bannersPerPage);
  const navigate = useNavigate();

  const titleRef = useRef([]);
  const offerRef = useRef([]);
  const descRef = useRef([]);
  const iconRef = useRef([]);
  const buttonRef = useRef([]);
  const tl = useGSAP();

  const nextBanner = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + bannersPerPage >= banners.length
        ? 0
        : prevIndex + bannersPerPage
    );
  };

  const prevBanner = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - bannersPerPage < 0
        ? banners.length - bannersPerPage
        : prevIndex - bannersPerPage
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextBanner();
    }, 4000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  useEffect(() => {
    tl.current = gsap.timeline();

    tl.current.fromTo(
      titleRef.current,
      { opacity: 0, y: -50 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        stagger: 0.2,
        ease: "power3.out",
      }
    );

    tl.current.fromTo(
      offerRef.current,
      { opacity: 0, scale: 0.8 },
      {
        opacity: 1,
        scale: 1,
        duration: 1,
        stagger: 0.2,
        ease: "elastic.out(1, 0.5)",
      },
      "<0.2"
    );

    tl.current.fromTo(
      descRef.current,
      { opacity: 0, x: -100 },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        stagger: 0.2,
        ease: "power3.out",
      },
      "<0.2"
    );

    tl.current.fromTo(
      iconRef.current,
      { opacity: 0, rotateY: 90 },
      {
        opacity: 1,
        rotateY: 0,
        duration: 1,
        stagger: 0.2,
        ease: "back.out(1.7)",
      },
      "<0.2"
    );
  }, [currentIndex, tl]);

  const handlers = useSwipeable({
    onSwipedLeft: nextBanner,
    onSwipedRight: prevBanner,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleBookNow = (service) => {
    navigate(`/offer-discounts/${service}`);
  };

  const handleButtonHover = (index) => {
    gsap.to(buttonRef.current[index], {
      scale: 1.1,
      duration: 0.3,
      ease: "power3.out",
    });
  };

  const handleButtonLeave = (index) => {
    gsap.to(buttonRef.current[index], {
      scale: 1,
      duration: 0.3,
      ease: "power3.out",
    });
  };

  const handleDotClick = (pageIndex) => {
    setCurrentIndex(pageIndex * bannersPerPage);
  };

  return (
    <div className="w-full max-w-[90rem] mx-auto px-8 py-12 relative">
      <h2 className="text-center text-xl md:text-3xl font-bold mb-6">
        Offers & Discounts
      </h2>

      <div {...handlers} className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {banners
          .slice(currentIndex, currentIndex + bannersPerPage)
          .map((banner, index) => (
            <div
              key={index}
              className={`${banner.bg} p-6 sm:p-10 rounded-lg shadow-lg transform hover:scale-105 transition duration-500 ease-in-out flex flex-col md:flex-row md:items-center md:justify-between promotional-banner`}
            >
              <div className="text-left max-w-full md:max-w-[60%] flex flex-col banner-content">
                <h2
                  ref={(el) => (titleRef.current[index] = el)}
                  className={`text-xl sm:text-2xl md:text-3xl font-bold mb-2 ${
                    banner.gender === "women"
                      ? "text-darkBlack"
                      : "text-darkBlack"
                  }`}
                >
                  {banner.title}
                </h2>

                <h3
                  ref={(el) => (offerRef.current[index] = el)}
                  className={`text-lg sm:text-xl md:text-2xl font-semibold mb-4 ${
                    banner.gender === "women"
                      ? "text-pink-600"
                      : "text-blue-600"
                  }`}
                >
                  {banner.offer}
                </h3>

                <p
                  ref={(el) => (descRef.current[index] = el)}
                  className={`text-sm sm:text-base mb-4 sm:mb-6 ${
                    banner.gender === "women" ? "text-black" : "text-black"
                  }`}
                >
                  {banner.description}
                </p>

                <button
                  ref={(el) => (buttonRef.current[index] = el)}
                  className="px-4 sm:px-6 py-2 sm:py-3 text-white font-bold rounded-md shadow-md transition-all duration-300 w-full md:w-auto mb-6 md:mb-0 banner-button"
                  style={{
                    backgroundColor:
                      banner.gender === "women" ? "#d81b60" : "#1976d2",
                  }}
                  onClick={() => handleBookNow(banner.title)}
                  onMouseEnter={() => handleButtonHover(index)}
                  onMouseLeave={() => handleButtonLeave(index)}
                  aria-label={`Book now for ${banner.title}`}
                >
                  Book Now
                </button>
              </div>

              <div
                ref={(el) => (iconRef.current[index] = el)}
                className="flex-shrink-0 w-full md:w-auto md:ml-8 mt-2 md:mt-0 flex justify-center banner-image"
              >
                {banner.icon}
              </div>
            </div>
          ))}
      </div>

      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-2 items-center">
        {Array.from({ length: totalPages }).map((_, pageIndex) => (
          <div
            key={pageIndex}
            className={`rounded-full cursor-pointer transition-all duration-300 ${
              pageIndex === Math.floor(currentIndex / bannersPerPage)
                ? "bg-black scale-90"
                : "bg-white"
            }`}
            onClick={() => handleDotClick(pageIndex)}
            style={{
              width:
                pageIndex === Math.floor(currentIndex / bannersPerPage)
                  ? "12px"
                  : "8px",
              height:
                pageIndex === Math.floor(currentIndex / bannersPerPage)
                  ? "12px"
                  : "8px",
            }}
          ></div>
        ))}
      </div>
      <style>{mobileStyles}</style>
    </div>
  );
}

export default PromotionalBanner;
